<template>
    <div class="dropdown" v-if="options">
        <input class="dropdown-input"
               @focus="showOptions()"
               @blur="exit()"
               @keyup="keyMonitor"
               v-model="searchFilter"
               maxlength="20"
               :placeholder="placeholder"/>

        <div class="dropdown-content" v-show="optionsShown" :append-to-body="true">
            <div v-for="(option, index) in filteredOptions" :key="index">
                <div class="dropdown-item" @mousedown="selectOption(option)">{{ option }}</div>
                <div v-if="hasEditTool" class="edit" @mousedown="editItem(option)"></div>
                <div v-if="hasDeleteTool" class="delete" @mousedown="deleteItem(option)"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DropDown',
    props: {
        options: {
            type: Array,
            required: true,
            default: []
        },
        placeholder: {
            type: String,
            required: false,
            default: 'Please select an option'
        },
        maxItem: {
            type: Number,
            required: false,
            default: 20,
            note: 'Max items showing'
        },
        showSelected: {
            type: Boolean,
            required: false,
            default: false
        },
        hasEditTool: {
            type: Boolean,
            required: false,
            default: false
        },
        hasDeleteTool: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            selected: '',
            optionsShown: false,
            searchFilter: ''
        }
    },
    computed: {
        filteredOptions() {
            return this.options.filter(function (option) {
                return this.searchFilter.length < 1 || option.indexOf(this.searchFilter) !== -1;
            }.bind(this));
        }
    },
    methods: {
        setDisplayValue(option) {
            this.searchFilter = option;
        },
        selectOption(option) {
            this.selected = option;
            this.optionsShown = false;
            this.$emit('selected', this.selected);
            this.searchFilter = this.showSelected ? option : '';
        },
        showOptions() {
            this.searchFilter = '';
            this.optionsShown = true;
        },
        exit() {
            this.optionsShown = false;
        },
        keyMonitor: function (event) {
            var selectedOption = this.filteredOptions[0] || this.selected;
            if (event.key === "Enter" && selectedOption) {
                this.selectOption(selectedOption);
            }
        },
        editItem: function (option) {
            var newName = window.prompt('Enter new tag name:', option);
            if (newName.length > 0) {
                this.$store.dispatch('editTag', {
                    tag: option,
                    name: newName,
                    userId: this.$route.params.userId,
                    dialogueId: this.$store.state.dialogueId
                });
            }
        },
        deleteItem: function (option) {
            if (window.confirm("Delete tag from all dialogues?")) {
                this.$store.dispatch('editTag', {
                    tag: option,
                    userId: this.$route.params.userId,
                    dialogueId: this.$store.state.dialogueId
                });
            }
        }
    },
    watch: {
        searchFilter() {
            if (this.filteredOptions.length === 0) {
                this.selected = this.searchFilter;
            } else {
                this.selected = this.filteredOptions[0];
            }
        },
        options() {
            this.searchFilter = '';
        }
    }
};
</script>

<style scoped>
.dropdown {

    display: inline-block;
    margin: auto;
}

.dropdown .dropdown-input {
    background: #fff;
    cursor: pointer;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    color: #333;
    display: block;
    font-size: 0.8em;
    padding: 6px;
    width: 148px;
}

.dropdown .dropdown-content {
    position: absolute;
    background-color: #fff;
    width: 160px;
    border: 1px solid #e7ecf5;
    box-shadow: 0px -8px 34px 0px rgba(0, 0, 0, 0.05);
    overflow: auto;
    z-index: 1;
    font-size: large;
    max-height: 260px;
}

.dropdown .dropdown-content .dropdown-item {
    color: black;
    font-size: 0.7em;
    line-height: 1em;
    padding: 8px;
    text-decoration: none;
    display: block;
    cursor: pointer;
}

.dropdown .dropdown-content .dropdown-item:hover {
    background-color: #e7ecf5;
}

.dropdown .dropdown:hover .dropdowncontent {
    display: block;
}

.delete {
    cursor: pointer;
    float: right;
    margin-right: 12px;
    margin-top: -25px;
}

.delete::before {
    content: '\0000d7';
}

.delete:hover {
    font-weight: 900;
}

.edit {
    cursor: pointer;
    float: right;
    margin-right: 26px;
    margin-top: -25px;
}

.edit::before {
    content: '\00270E';
}

.edit:hover {
    font-weight: 900;
}
</style>