<template>
    <div>
        <div class="label-name" :style="labelColor()">{{label}}</div>
        <div class="label-close" :style="labelColor()" v-on:click="removeLabel(label, $event)"></div>
    </div>
</template>

<script>
export default {
    name: "Label",
    props: {
        label: {
            type: String,
            required: true,
        },
        extraData: {
            type: Object,
            required: false,
        },
        filterName: {
            type: String,
            required: false,
        },
        colorBg: {
            type: Boolean,
            required: false,
        },
        closeCallback: {
            type: Function
        }
    },
    methods: {
        removeLabel(label, event) {
            event.preventDefault();
            event.stopPropagation();

            this.closeCallback(label, this.filterName || this.extraData);
        },
        labelColor() {
            if (this.filterName && this.filterName !== 'filterByTag') {
                return "background-color: #1A73E8;color: white;";
            }

            var hash = 0;
            for (var i = 0; i < this.label.length; i++) {
                hash = this.label.charCodeAt(i) + ((hash << 5) - hash);
            }
            var c = (hash & 0x00FFFFFF)
                .toString(16)
                .toUpperCase();
            var backgroundColor = "00000".substring(0, 6 - c.length) + c;

            if (this.colorBg) {
                var bigint = parseInt(backgroundColor, 16);
                var r = (bigint >> 16) & 255;
                var g = (bigint >> 8) & 255;
                var b = bigint & 255;
                var textColor = (r * 0.299 + g * 0.587 + b * 0.114) > 186 ? '000000' : 'ffffff';
                return "background-color: #" + backgroundColor + ";color: #" + textColor + ";";
            } else {
                return "border-bottom: #" + backgroundColor + ";border-bottom-width: 2px;border-bottom-style: solid;";
            }
        }
    }
}
</script>

<style scoped>
.label-name {
    -webkit-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px;
    display: inline-block;
    padding: 0 0.16em 1px 4px;
    vertical-align: bottom;
    background-color: #eee;
    height: 16px;
    font-size: 14px;
    color: #666;
}

.label-close {
    -webkit-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
    display: inline-block;
    font-size: 15px;
    overflow: hidden;
    padding: 0 4px 1px 0.16em;
    vertical-align: bottom;
    font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
    font-size: .75rem;
    letter-spacing: .3px;
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    height: 16px;
    line-height: 18px;
    margin-right: 2px;
    white-space: nowrap;
    background-color: #eee;
}

.label-close::before {
    content: '\0000d7';
}

.label-close:hover {
    font-weight: 900;
}

</style>