const mergesPacks = {
    "starterPack0": {
        "name": "Starter pack",
        "hard": 50,
        "lives": 200
    },
    "starterPack": {
        "name": "Super pack",
        "hard": 100,
        "lives": 350,
        "wands": 250,
        "worker": 72
    },
    "starterPack2": {
        "name": "Mega pack",
        "hard": 1000,
        "lives": 1500,
        "wands": 500,
        "soft": 3000,
        "worker": 120
    },
    "gold500": {
        "name": "Stack of rubies",
        "hard": 200
    },
    "gold1800": {
        "name": "Pile of rubies",
        "hard": 900
    },
    "gold3800": {
        "name": "Heap of rubies",
        "hard": 2100
    },
    "gold6000": {
        "name": "Mountain of rubies",
        "hard": 3500
    },
    "gold25000": {
        "name": "Rubies treasure",
        "hard": 14000
    },
    "kit1": {
        "name": "Dream Force",
        "hard": 100
    },
    "kit3": {
        "name": "Boost Bundle",
        "hard": 300
    },
    "kit10": {
        "name": "Adventure Pack",
        "hard": 1000
    },
    "kit50": {
        "name": "Legend's Arsenal",
        "hard": 5000
    },
    "island1": {
        "name": "Fortune Isle",
        "hard": 100
    },
    "island2": {
        "name": "Emerald Isle",
        "hard": 200
    },
    "island5": {
        "name": "Crimson Cove",
        "hard": 500
    },
    "island10": {
        "name": "Azure Bay",
        "hard": 1000
    },
    "island15": {
        "name": "Jewel Oasis",
        "hard": 1500
    },
    "island20": {
        "name": "Serene Island",
        "hard": 2000
    },
    "island30": {
        "name": "Gold Haven",
        "hard": 3000
    },
    "island45": {
        "name": "Sapphire Bay",
        "hard": 4500
    }
};

const match3Packs = {
    "starterPack0": {
        "name": "Starter pack",
        "hard": 500,
        "unlimitedLives": 24,
        "booster5": 1,
        "booster6": 1,
        "booster7": 1
    },
    "starterPack": {
        "name": "Super pack",
        "hard": 1800,
        "unlimitedLives": 72,
        "booster5": 2,
        "booster6": 2,
        "booster7": 2
    },
    "starterPack2": {
        "name": "Mega pack",
        "hard": 12000,
        "unlimitedLives": 216,
        "booster5": 14,
        "booster6": 14,
        "booster7": 14
    },
    "addMoves1": {
        "name": "Add 5 moves",
        "hard": 200
    },
    "addMoves2": {
        "name": "Add 10 moves",
        "hard": 400
    },
    "addMoves3": {
        "name": "Add 15 moves",
        "hard": 600
    },
    "addMoves5": {
        "name": "Add 15 moves",
        "hard": 1000
    },
    "addMoves": {
        "name": "Add moves",
        "hard": 200
    },
    "sweetPack": {
        "name": "Newbie Pack",
        "hard": 500,
        "booster5": 1,
        "booster6": 1,
        "booster7": 1
    },
    "jamPack": {
        "name": "Apprentice Pack",
        "hard": 1800,
        "booster5": 2,
        "booster6": 2,
        "booster7": 2
    },
    "tastyPack": {
        "name": "Pro Pack",
        "hard": 3800,
        "booster0": 3,
        "booster1": 3,
        "booster5": 3,
        "booster6": 3,
        "booster7": 3
    },
    "honeyPack": {
        "name": "Master Pack",
        "hard": 6000,
        "booster0": 5,
        "booster1": 5,
        "booster5": 5,
        "booster6": 5,
        "booster7": 5
    },
    "epicPack": {
        "name": "Champion Pack",
        "hard": 25000,
        "booster0": 18,
        "booster1": 18,
        "booster5": 18,
        "booster6": 18,
        "booster7": 18
    },
    "kit3": {
        "name": "Boost Bundle",
        "hard": 900
    },
    "kit10": {
        "name": "Adventure Pack",
        "hard": 3000
    },
    "kit50": {
        "name": "Legend's Arsenal",
        "hard": 11000
    }
};

const packs = [{
    name: "Riddles",
    rewards: match3Packs
}, {
    name: "Heroes",
    rewards: match3Packs
}, {
    name: "Runes",
    rewards: match3Packs
}, {
    name: "Adventure",
    rewards: match3Packs
}, {
    name: "Wordsoup",
    rewards: {
        "starterPack0": {
            "name": "Starter pack",
            "hard": 800
        },
        "starterPack": {
            "name": "Super pack",
            "hard": 2600
        },
        "starterPack2": {
            "name": "Mega pack",
            "hard": 17000
        }
    }
}, {
    name: "Scramble",
    rewards: {
        "starterPack0": {
            "name": "Starter pack",
            "hard": 200,
            "booster13": 2,
            "booster14": 2,
            "booster15": 2
        },
        "starterPack": {
            "name": "Super pack",
            "hard": 800,
            "booster13": 6,
            "booster14": 6,
            "booster15": 6
        },
        "starterPack2": {
            "name": "Mega pack",
            "hard": 8000,
            "booster13": 30,
            "booster14": 30,
            "booster15": 30
        },
        "sweetPack": {
            "name": "Newbie Pack",
            "hard": 500,
            "booster13": 2,
            "booster14": 2,
            "booster15": 2
        },
        "jamPack": {
            "name": "Apprentice Pack",
            "hard": 1800,
            "booster13": 3,
            "booster14": 3,
            "booster15": 3
        },
        "tastyPack": {
            "name": "Pro Pack",
            "hard": 3800,
            "booster13": 5,
            "booster14": 5,
            "booster15": 5
        },
        "honeyPack": {
            "name": "Master Pack",
            "hard": 6000,
            "booster13": 7,
            "booster14": 7,
            "booster15": 7
        },
        "epicPack": {
            "name": "Champion Pack",
            "hard": 25000,
            "booster13": 30,
            "booster14": 30,
            "booster15": 30
        }
    }
}, {
    name: "Crocword",
    rewards: {
        "starterPack0": {
            "name": "Starter pack",
            "hard": 800
        },
        "starterPack": {
            "name": "Super pack",
            "hard": 2600
        },
        "starterPack2": {
            "name": "Mega pack",
            "hard": 17000
        }
    }
}, {
    name: "Olympics",
    rewards: {
        "starterPack0": {
            "name": "Starter pack",
            "hard": 800
        },
        "starterPack": {
            "name": "Super pack",
            "hard": 2600
        },
        "starterPack2": {
            "name": "Mega pack",
            "hard": 17000
        }
    }
}, {
    name: "Zenmatch",
    rewards: {
        "kit3": {
            "name": "Boost Bundle",
            "hard": 300
        },
        "kit10": {
            "name": "Adventure Pack",
            "hard": 1000
        },
        "kit50": {
            "name": "Legend's Arsenal",
            "hard": 5000
        }
    }
}, {
    name: "Tripeaks",
    rewards: {
        "starterPack0": {
            "name": "Starter pack",
            "hard": 200,
            "booster0": 0,
            "booster1": 0,
            "booster2": 0,
            "booster10": 2,
            "booster11": 2,
            "booster12": 2
        },
        "starterPack": {
            "name": "Super pack",
            "hard": 800,
            "booster0": 0,
            "booster1": 0,
            "booster2": 0,
            "booster10": 6,
            "booster11": 6,
            "booster12": 6
        },
        "starterPack2": {
            "name": "Mega pack",
            "hard": 8000,
            "booster0": 0,
            "booster1": 0,
            "booster2": 0,
            "booster10": 30,
            "booster11": 30,
            "booster12": 30
        },
        "addMoves1": {
            "name": "Add 5 cards and 1 Joker",
            "hard": 200
        },
        "addMoves2": {
            "name": "Add 10 cards and 1 Joker",
            "hard": 400
        },
        "addMoves3": {
            "name": "Add 15 cards and 1 Joker",
            "hard": 600
        },
        "addMoves5": {
            "name": "Add 15 cards and 2 Jokers",
            "hard": 1000
        },
        "addMoves": {
            "name": "Add 5 cards",
            "hard": 200
        },
        "sweetPack": {
            "name": "Newbie Pack",
            "hard": 500,
            "booster10": 2,
            "booster11": 2,
            "booster12": 2
        },
        "jamPack": {
            "name": "Apprentice Pack",
            "hard": 1800,
            "booster10": 3,
            "booster11": 3,
            "booster12": 3
        },
        "tastyPack": {
            "name": "Pro Pack",
            "hard": 3800,
            "booster10": 5,
            "booster11": 5,
            "booster12": 5
        },
        "honeyPack": {
            "name": "Master Pack",
            "hard": 6000,
            "booster10": 7,
            "booster11": 7,
            "booster12": 7
        },
        "epicPack": {
            "name": "Champion Pack",
            "hard": 25000,
            "booster10": 30,
            "booster11": 30,
            "booster12": 30
        },
        "kit3": {
            "name": "Boost Bundle",
            "hard": 300
        },
        "kit10": {
            "name": "Adventure Pack",
            "hard": 1000
        },
        "kit50": {
            "name": "Legend's Arsenal",
            "hard": 5000
        }
    }
}, {
    name: "Spades",
    rewards: {
        "starterPack0": {
            "name": "Starter pack",
            "hard": 200,
            "booster0": 0,
            "booster1": 0,
            "booster2": 0,
            "booster10": 2,
            "booster11": 2,
            "booster12": 2
        },
        "starterPack": {
            "name": "Super pack",
            "hard": 800,
            "booster0": 0,
            "booster1": 0,
            "booster2": 0,
            "booster10": 6,
            "booster11": 6,
            "booster12": 6
        },
        "starterPack2": {
            "name": "Mega pack",
            "hard": 8000,
            "booster0": 0,
            "booster1": 0,
            "booster2": 0,
            "booster10": 30,
            "booster11": 30,
            "booster12": 30
        },
        "addMoves1": {
            "name": "Add 5 cards and 1 Joker",
            "hard": 200
        },
        "addMoves2": {
            "name": "Add 10 cards and 1 Joker",
            "hard": 400
        },
        "addMoves3": {
            "name": "Add 15 cards and 1 Joker",
            "hard": 600
        },
        "addMoves5": {
            "name": "Add 15 cards and 2 Jokers",
            "hard": 1000
        },
        "addMoves": {
            "name": "Add 5 cards",
            "hard": 200
        },
        "sweetPack": {
            "name": "Newbie Pack",
            "hard": 500,
            "booster10": 2,
            "booster11": 2,
            "booster12": 2
        },
        "jamPack": {
            "name": "Apprentice Pack",
            "hard": 1800,
            "booster10": 3,
            "booster11": 3,
            "booster12": 3
        },
        "tastyPack": {
            "name": "Pro Pack",
            "hard": 3800,
            "booster10": 5,
            "booster11": 5,
            "booster12": 5
        },
        "honeyPack": {
            "name": "Master Pack",
            "hard": 6000,
            "booster10": 7,
            "booster11": 7,
            "booster12": 7
        },
        "epicPack": {
            "name": "Champion Pack",
            "hard": 25000,
            "booster10": 30,
            "booster11": 30,
            "booster12": 30
        }
    }
}, {
    name: "Differences",
    rewards: {
        "starterPack0": {
            "name": "Starter pack",
            "hard": 200,
            "booster0": 0,
            "booster1": 0,
            "booster2": 0,
            "booster8": 2,
            "booster9": 2
        },
        "starterPack": {
            "name": "Super pack",
            "hard": 800,
            "booster0": 0,
            "booster1": 0,
            "booster2": 0,
            "booster8": 6,
            "booster9": 6
        },
        "starterPack2": {
            "name": "Mega pack",
            "hard": 8000,
            "booster0": 0,
            "booster1": 0,
            "booster2": 0,
            "booster8": 30,
            "booster9": 30
        },
        "sweetPack": {
            "name": "Newbie Pack",
            "hard": 500,
            "booster8": 2,
            "booster9": 2
        },
        "jamPack": {
            "name": "Apprentice Pack",
            "hard": 1800,
            "booster8": 3,
            "booster9": 3
        },
        "tastyPack": {
            "name": "Pro Pack",
            "hard": 3800,
            "booster8": 5,
            "booster9": 5
        },
        "honeyPack": {
            "name": "Master Pack",
            "hard": 6000,
            "booster8": 7,
            "booster9": 7
        },
        "epicPack": {
            "name": "Champion Pack",
            "hard": 25000,
            "booster8": 30,
            "booster9": 30
        }
    }
}, {
    name: "Mergecraft",
    rewards: mergesPacks
}, {
    name: "Fairy",
    rewards: mergesPacks
}, {
    name: "Wondermerge",
    rewards: mergesPacks
}, {
    name: "Hustlemerge",
    rewards: mergesPacks
}, {
    name: "Magicwords",
    rewards: {
        "starterPack0": {
            "name": "Starter pack",
            "hard": 800
        },
        "starterPack": {
            "name": "Super pack",
            "hard": 2600
        },
        "starterPack2": {
            "name": "Mega pack",
            "hard": 17000
        }
    }
}];
export default {
    packs: packs
};