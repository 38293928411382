<template>
    <div ref="sidebarMenuRef" class="v-sidebar-menu" :class="sidebarClass" :style="{'max-width': sidebarWidth}">
        <slot name="header"/>
        <sidebar-menu-scroll>
            <ul class="sidebar-menu" :style="{'width': sidebarWidth, 'position': 'static !important'}">
                <sidebar-menu-item v-for="item in computedMenu" :key="item.id" :item="item">
                    <template #dropdown-icon="{ isOpen }">
                        <slot name="dropdown-icon" v-bind="{ isOpen }">
                            <span class="sidebar-arrow_default"/>
                        </slot>
                    </template>
                </sidebar-menu-item>
            </ul>
        </sidebar-menu-scroll>
        <slot name="footer"/>
        <button v-if="!hideToggle" class="sidebar-toggle-btn" @click="onToggleClick">
            <slot name="toggle-icon">
                <span class="sidebar-toggle-btn_default"/>
            </slot>
        </button>
    </div>
</template>

<script>
import {provide, watch, toRefs, getCurrentInstance, onMounted, onUnmounted} from 'vue'
import useMenu from './use/usemenu'

import SidebarMenuItem from './sidebarmenuitem.vue'
import SidebarMenuScroll from './sidebarmenuscroll.vue'

export default {
    name: 'SidebarMenu',
    components: {
        SidebarMenuItem,
        SidebarMenuScroll
    },
    props: {
        menu: {
            type: Array,
            required: true
        },
        collapsed: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: '290px'
        },
        widthCollapsed: {
            type: String,
            default: '65px'
        },
        showChild: {
            type: Boolean,
            default: false
        },
        showOneChild: {
            type: Boolean,
            default: false
        },
        rtl: {
            type: Boolean,
            default: false
        },
        relative: {
            type: Boolean,
            default: false
        },
        hideToggle: {
            type: Boolean,
            default: false
        },
        disableHover: {
            type: Boolean,
            default: false
        }
    },
    emits: {
        'item-click'(event, item) {
            return !!(event && item)
        },
        'update:collapsed'(collapsed) {
            return !!(typeof collapsed === 'boolean')
        }
    },
    setup(props, context) {
        provide('sidebar-props', props)

        const {
            sidebarMenuRef,
            isCollapsed,
            computedMenu,
            sidebarWidth,
            sidebarClass,
            onToggleClick,
            onItemClick,
            onRouteChange,
            unsetMobileItem
        } = useMenu(props, context)

        provide('emitItemClick', onItemClick)
        provide('emitScrollUpdate')
        provide('onRouteChange', onRouteChange)

        const {collapsed} = toRefs(props)
        isCollapsed.value = collapsed.value

        watch(() => props.collapsed, (currentCollapsed) => {
            unsetMobileItem()
            isCollapsed.value = currentCollapsed
        })

        const router = getCurrentInstance().appContext.config.globalProperties.$router
        if (!router) {
            onMounted(() => {
                window.addEventListener('hashchange', onRouteChange)
            })
            onUnmounted(() => {
                window.removeEventListener('hashchange', onRouteChange)
            })
        }

        return {
            sidebarMenuRef,
            isCollapsed,
            computedMenu,
            sidebarWidth,
            sidebarClass,
            onToggleClick,
            onItemClick,
            onRouteChange
        }
    }
}
</script>

<style>
.v-sidebar-menu {
    position: fixed;
    float: left;
    top: 0;
    left: 0;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    z-index: 999;
    width: 100%;
    text-align: left;
    -webkit-transition: max-width .3s ease;
    transition: max-width .3s ease;
    background-color: #616161
}

.v-sidebar-menu,
.v-sidebar-menu * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.v-sidebar-menu .sidebar-scroll-wrapper {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    min-height: 0
}

.v-sidebar-menu .sidebar-scroll-wrapper:active .sidebar-scroll-bar,
.v-sidebar-menu .sidebar-scroll-wrapper:focus .sidebar-scroll-bar,
.v-sidebar-menu .sidebar-scroll-wrapper:hover .sidebar-scroll-bar {
    opacity: 1
}

.v-sidebar-menu .sidebar-scroll-overflow {
    overflow: hidden;
    width: 100%;
    height: 100%
}

.v-sidebar-menu .sidebar-scroll {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    width: calc(100% + 17px)
}

.v-sidebar-menu .sidebar-scroll-bar {
    position: absolute;
    top: 2px;
    bottom: 2px;
    right: 2px;
    width: 6px;
    z-index: 5;
    opacity: 0
}

.v-sidebar-menu .sidebar-scroll-bar,
.v-sidebar-menu .sidebar-scroll-thumb {
    border-radius: 4px;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease
}

.v-sidebar-menu .sidebar-scroll-thumb {
    display: block;
    background-color: #aaa;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    opacity: .5
}

.v-sidebar-menu .sidebar-scroll-thumb:hover {
    opacity: 1
}

.v-sidebar-menu .sidebar-dropdown,
.v-sidebar-menu .sidebar-menu {
    padding: 0;
    margin: 0;
    list-style: none
}

.v-sidebar-menu .sidebar-dropdown {
    padding: 5px
}

.v-sidebar-menu .sidebar-item {
    position: static!important;
    display: block;
    width: 100%;
    white-space: nowrap
}

.v-sidebar-menu .sidebar-link {
    position: static!important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    padding: 10px 15px;
    line-height: 30px;
    text-decoration: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}

.v-sidebar-menu .sidebar-link_active {
    font-weight: bold;
    text-decoration: underline;
}

.v-sidebar-menu .sidebar-link_disabled {
    opacity: .4;
    pointer-events: none
}

.v-sidebar-menu .sidebar-link_level-1 .sidebar-icon {
    height: 35px;
    line-height: 35px;
    width: 35px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    text-align: center;
    border-radius: 3px
}

.v-sidebar-menu .sidebar-link_level-2 {
    padding: 0px 15px;
    height: 28px;
    line-height: 28px;
}

.v-sidebar-menu .sidebar-icon {
    display: inline-block;
    margin-right: 15px;
    z-index: 20
}

.v-sidebar-menu .sidebar-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.v-sidebar-menu .sidebar-title>span:first-child {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

.v-sidebar-menu .sidebar-title_hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0
}

.v-sidebar-menu .sidebar-arrow {
    width: 30px;
    height: 30px;
    font-size: 14px;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.v-sidebar-menu .sidebar-arrow_default {
    position: relative;
    width: 12px;
    height: 12px;
    -webkit-transition: -webkit-transform .3s ease;
    transition: -webkit-transform .3s ease;
    transition: transform .3s ease;
    transition: transform .3s ease, -webkit-transform .3s ease
}

.v-sidebar-menu .sidebar-arrow_default:before {
    content: "";
    width: 6px;
    height: 6px;
    border-left: 2px solid;
    border-bottom: 2px solid;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    display: inline-block;
    position: absolute;
    top: 2px;
    left: 0
}

.v-sidebar-menu .sidebar-arrow_open .sidebar-arrow_default {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.v-sidebar-menu .sidebar-header {
    font-size: 14px;
    font-weight: 600;
    padding: 10px 15px;
    white-space: nowrap;
    text-transform: uppercase;
    color: rgba(38, 38, 38, .7)
}

.v-sidebar-menu .sidebar-badge_default {
    padding: 0 6px;
    font-size: 12px;
    border-radius: 3px;
    height: 20px;
    line-height: 20px;
    font-weight: 600;
    text-transform: uppercase
}

.v-sidebar-menu .sidebar-toggle-btn {
    width: 100%;
    height: 30px;
    font-size: 20px;
    text-align: center;
    cursor: pointer;
    border: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: white;
    background-color: #616161
}

.v-sidebar-menu .sidebar-toggle-btn_default {
    position: relative;
    width: 14px;
    height: 14px
}

.v-sidebar-menu .sidebar-toggle-btn_default:before {
    content: "";
    display: inline-block;
    height: 12px;
    border-left: 2px solid;
    position: absolute;
    left: 0;
    top: 0
}

.v-sidebar-menu .sidebar-toggle-btn_default:after {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    border-left: 2px solid;
    border-bottom: 2px solid;
    position: absolute;
    left: 4px;
    top: 2px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.v-sidebar-menu.sidebar_collapsed .sidebar-link_level-1.sidebar-link_hover,
.v-sidebar-menu.sidebar_collapsed .sidebar-link_level-1:hover {
    background-color: transparent!important
}

.v-sidebar-menu.sidebar_collapsed .sidebar-toggle-btn_default {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1)
}

.v-sidebar-menu.sidebar_collapsed.sidebar_rtl .sidebar-toggle-btn_default {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
}

.v-sidebar-menu.sidebar_collapsed .sidebar-scroll-wrapper {
    width: calc(100% + 8px)
}

.v-sidebar-menu.sidebar_rtl {
    right: 0;
    left: inherit;
    text-align: right;
    direction: rtl
}

.v-sidebar-menu.sidebar_rtl .sidebar-icon {
    margin-left: 15px;
    margin-right: 0
}

.v-sidebar-menu.sidebar_rtl .sidebar-arrow_default {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1)
}

.v-sidebar-menu.sidebar_rtl .sidebar-arrow_open .sidebar-arrow_default {
    -webkit-transform: rotate(-90deg) scaleX(-1);
    transform: rotate(-90deg) scaleX(-1)
}

.v-sidebar-menu.sidebar_rtl .sidebar-toggle-btn_default {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1)
}

.v-sidebar-menu.sidebar_rtl .sidebar-scroll-bar {
    right: inherit;
    left: 2px
}

.v-sidebar-menu.sidebar_relative {
    position: relative;
    height: 116%
}

.v-sidebar-menu .expand-enter-active,
.v-sidebar-menu .expand-leave-active {
    -webkit-transition: height .3s ease;
    transition: height .3s ease;
    overflow: hidden
}

.v-sidebar-menu .expand-enter-from,
.v-sidebar-menu .expand-leave-to {
    height: 0!important
}

.v-sidebar-menu .slide-animation-enter-active,
.v-sidebar-menu .slide-animation-leave-active {
    -webkit-transition: width .3s ease;
    transition: width .3s ease
}

.v-sidebar-menu .slide-animation-enter-from,
.v-sidebar-menu .slide-animation-leave-to {
    width: 0 !important
}

.v-sidebar-menu .sidebar-link {
    color: #fff
}

.v-sidebar-menu .sidebar-link_level-1.sidebar-link_active {
    -webkit-box-shadow: 4px 0 0 0 #999999 inset;
    box-shadow: inset 4px 0 0 0 #999999
}

.v-sidebar-menu .sidebar-link_level-1 .sidebar-icon {
    background-color: #bbc5d6
}

.v-sidebar-menu .sidebar-link_hover {
    background-color: hsla(0, 0%, 94.9%, .5)
}

.v-sidebar-menu .sidebar-link_mobile {
    color: #fff
}

.v-sidebar-menu.sidebar_expanded .sidebar-link_level-1.sidebar-link_open {
    color: #fff;
    background-color: #888888
}

.v-sidebar-menu.sidebar_expanded .sidebar-link_level-1.sidebar-link_open .sidebar-icon {
    background-color: #888888
}

.v-sidebar-menu.sidebar_collapsed .sidebar-link_level-1.sidebar-link_hover .sidebar-icon {
    color: #fff;
    background-color: #888888
}

.v-sidebar-menu .sidebar-dropdown {
    background-color: #a3a3a3;
}

.v-sidebar-menu .sidebar-mobile-bg {
    background-color: #888888;
}

.v-sidebar-menu.sidebar_rtl .sidebar-link_level-1.sidebar-link_active {
    -webkit-box-shadow: -5px 0 0 0 #888888 inset;
    box-shadow: inset -5px 0 0 0 #888888
}

.v-sidebar-menu .sidebar-link_level-1.sidebar-link_active .sidebar-icon {
    color: #fff;
    background-color: #262626
}

.item-icon {
    background-color: transparent !important;
}

span.unread {
    float: right;
    margin-top: 1px;
    margin-left: 5px;
    font-size: small;
}

</style>
