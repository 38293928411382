/**
* Created by Andrey Popov on 12/9/20.
*/

<template>
    <router-view></router-view>
</template>

<script>

export default {
    name: 'App'
};
</script>

<style>

html, body {
    height: 95%;
    margin: 0px;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 95%;
}

.content {
    overflow: auto;
    overflow-x: hidden;
    height: 106%;
}
</style>
