/**
 * Created by Andrey Popov on 12/9/20.
 */

import _ from "lodash";

import axios from "axios";
import config from "./config";
import utils from "./utils";
import router from "./router/index";
import createHttp from "./http";

const fetchDialogues = ({ commit, state }) => {
    commit("DIALOGUES_FETCHING");

    var games = getGames();
    var folder = router.currentRoute.value.params.folder.split(":")[0];
    var payersOnly = false;
    if (folder.startsWith("$")) {
        folder = folder.replace("$", "");
        payersOnly = true;
    }
    axios.all(games.map(function (game) {
        return createHttp(state.token).get(game.url + "/chat/messages", {
            params: {
                folder: folder,
                payersOnly: payersOnly,
                enOnly: false,
                filter: state.query,
                reqCount: state.page.selected,
                offset: games.length === 1 ? state.page.selected * state.page.size : undefined,
                limit: games.length === 1 ? state.page.size : undefined,
                tags: state.filter.byTag,
                languages: state.filter.byLanguage,
                statuses: state.filter.byStatus,
                sources: state.filter.bySource,
                respondingPersons: state.filter.byRespondingPerson,
                unreadOnly: state.filter.unreadOnlyMode,
                withAnswers: state.filter.withAnswers,
                latestVersion: state.filter.latestVersion,
                from: state.filter.byDate.from,
                to: state.filter.byDate.to,
                episodes: state.filter.byEpisode,
                levels: state.filter.byLevel
            }
        });
    })).then(function (results) {
        var allDialogues = [];
        games.forEach(function (game, index) {
            results[index].data.forEach((dialogue) => {
                dialogue.game = game.name;
                if (!dialogue.userId) {
                    dialogue.userId = dialogue.id;
                }
                dialogue.id = utils.createDialogueId(game.name, dialogue.dialogueId);
                allDialogues.push(dialogue);
            });
        });
        allDialogues.sort(function (a, b) {
            return new Date(b.postDate) - new Date(a.postDate);
        });
        commit("DIALOGUES_FETCHED", allDialogues);
    }).catch(async (error) => {
        if (error.response && error.response.status === 403) {
            localStorage.removeItem("token");
            await router.push({ path: "/login" });
        } else {
            throw error;
        }
    });
};

const fetchUserCard = ({ dispatch, commit, state }, data) => {
    commit("USER_CARD_FETCHING", data.dialogueId === undefined);

    createHttp(state.token).get(getGames()[0].url + `/chat/messages/${encodeURIComponent(data.userId)}/${data.dialogueId || "none"}`)
        .then((resp) => {
            if (state.zen && state.zen.active && state.zen.userIds.indexOf(data.userId) !== -1 && !data.stayOnCard) {
                var messages = resp.data.messages;
                if (messages && messages.length > 0 && ["new", "renewal", "pending"].indexOf(messages[messages.length - 1].status) === -1) {
                    dispatch("zenCardProcessed", {
                        userid: data.userId,
                        game: getGames()[0].name
                    });
                    return;
                }
            }

            commit("USER_CARD_FETCHED", resp.data);
        }).catch(async (error) => {
            if (error.response && error.response.status === 403) {
                localStorage.removeItem("token");
                await router.push({ path: "/login" });
            } else {
                throw error;
            }
        });
};

const fetchUserSummary = ({ commit, state }, userId) => {
    createHttp(state.token).get(getGames()[0].url + `/chat/messages/${encodeURIComponent(userId)}/none`)
        .then((resp) => {
            var summary = [
                { name: "Name", collection: "userNames", path: "name" },
                { name: "Episode", collection: "users", path: "episode" },
                { name: "Level", collection: "users", path: "level" },
                { name: "Version", collection: "userappversion", path: "appversion" },
                { name: "Channel", collection: "users", path: "channel" },
                {
                    name: "Registered", collection: "users", path: "registered", type: "date" 
                },
                {
                    name: "Visited", collection: "users", path: "visited", type: "date" 
                },
                { name: "Cheater", collection: "users", path: "cheater" },
                { name: "UserAgent", collection: "useragents", type: "agent" },
                {
                    name: "Country", collection: "users", path: "country", type: "country" 
                },
                {
                    name: "Ids history'", collection: "useridshistory", path: "data"
                }
            ]
                .filter((prop) => utils.hasPropValues(resp.data, prop))
                .map((prop) => ({
                    header: prop.name,
                    type: prop.type,
                    values: utils.getPropValues(resp.data, prop)
                }));
            commit("USER_SUMMARY_FETCHED", summary);
        }).catch(() => {
            commit("USER_SUMMARY_FETCHED", []);
        });
};

const fetchUnreadCount = ({ commit, state }) => {
    var games = config.games.filter(function (game) {
        return game.url !== undefined && game.unreadCounter === undefined;
    });

    axios.all(games.map(function (game) {
        return createHttp(state.token).get(game.url + "/chat/messages/calc_unread");
    })).then(function (results) {
        var unreadCount = {
            Inbox: 0
        };
        games.forEach(function (game, index) {
            unreadCount[game.name] = results[index].data.Inbox;
            unreadCount.Inbox += results[index].data.Inbox;
        });

        config.games.filter(function (game) {
            return game.unreadCounter !== undefined;
        }).forEach(function (game) {
            unreadCount[game.name] = game.unreadCounter;
        });
        commit("UNREAD_COUNT_FETCHED", unreadCount);
    });
};

const fetchZenTotalCount = ({ commit, state }, gameName) => {
    var gameUrl = config.games.filter(function (game) {
        return game.name === gameName;
    })[0].url;

    createHttp(state.token).get(gameUrl + "/chat/messages/calc", {
        params: {
            folder: "Inbox",
            tags: state.zen.tags || [],
            unreadOnly: true,
            statuses: ["new", "renewal", "pending"]
        }
    }).then(function (result) {
        commit("ZEN_TOTAL_COUNT_FETCHED", result.data);
    });
};

const fetchTags = ({ commit, state }) => {
    var games = config.games.filter(function (game) {
        return game.url !== undefined;
    });

    axios.all(games.map(function (game) {
        return createHttp(state.token).get(game.url + "/chat/fetchtags");
    })).then(function (results) {
        var tags = {
            all: []
        };
        games.forEach(function (game, index) {
            var gameTags = results[index].data.map((d) => d.tag);
            gameTags.forEach(function (tag) {
                if (tags.all.indexOf(tag) === -1) {
                    tags.all.push(tag);
                }
            });
            tags[game.name] = gameTags;
        });

        tags.all.sort(function (a, b) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
        });
        commit("TAGS_FETCHED", tags);
    });
};

const fetchInboxCount = ({ commit, state }, gameName) => {
    var gameUrl = config.games.filter(function (game) {
        return game.name === gameName;
    })[0].url;

    var folder = router.currentRoute.value.params.folder.split(":")[0];
    var payersOnly = false;
    if (folder.startsWith("$")) {
        payersOnly = true;
    }

    createHttp(state.token).get(gameUrl + "/chat/messages/calc", {
        params: {
            folder: folder,
            payersOnly: payersOnly,
            enOnly: false,
            tags: state.filter.byTag,
            languages: state.filter.byLanguage,
            statuses: state.filter.byStatus,
            sources: state.filter.bySource,
            respondingPersons: state.filter.byRespondingPerson,
            unreadOnly: state.filter.unreadOnlyMode,
            withAnswers: state.filter.withAnswers,
            latestVersion: state.filter.latestVersion,
            from: state.filter.byDate.from,
            to: state.filter.byDate.to,
            episodes: state.filter.byEpisode,
            levels: state.filter.byLevel
        }
    }).then(function (result) {
        commit(payersOnly ? "INBOX_COUNT_FETCHED_PAYERS" : "INBOX_COUNT_FETCHED", result.data);
    });
};

// eslint-disable-next-line no-unused-vars
const search = _.debounce(({ dispatch, state, commit }, data) => {
    commit("SEARCH_QUERY", data.query);
    var folder = router.currentRoute.value.params.folder.split(":")[0];
    if (data.query.length > 2 || folder.indexOf("Inbox") === -1) {
        dispatch("fetchDialogues");
    } else {
        commit("DIALOGUES_FETCHED", []);
    }
}, 300);

// eslint-disable-next-line no-unused-vars
const filterByTag = ({ dispatch, commit }, data) => {
    commit("CHANGE_FILTER_TAG", data);
};

// eslint-disable-next-line no-unused-vars
const filterByEpisode = ({ dispatch, commit }, data) => {
    commit("CHANGE_FILTER_EPISODE", data);
};
// eslint-disable-next-line no-unused-vars
const filterByLevel = ({ dispatch, commit }, data) => {
    commit("CHANGE_FILTER_LEVEL", data);
};
// eslint-disable-next-line no-unused-vars
const filterByLanguage = ({ dispatch, commit }, data) => {
    commit("CHANGE_FILTER_LANGUAGE", data);
};
// eslint-disable-next-line no-unused-vars
const filterByStatus = ({ dispatch, commit }, data) => {
    commit("CHANGE_FILTER_STATUS", data);
};
// eslint-disable-next-line no-unused-vars
const filterBySource = ({ dispatch, commit }, data) => {
    commit("CHANGE_FILTER_SOURCE", data);
};
// eslint-disable-next-line no-unused-vars
const filterByRespondingPerson = ({ dispatch, commit }, data) => {
    commit("CHANGE_FILTER_RESPONDING_PERSON", data);
};
// eslint-disable-next-line no-unused-vars
const filterByDate = ({ dispatch, commit }, data) => {
    commit("CHANGE_FILTER_DATE", data);
};

const changeWithAnswersMode = ({ commit }, data) => {
    commit("WITH_ANSWERS_MODE_CHANGED", data);
};

const changeLatestVersionMode = ({ commit }, data) => {
    commit("LATEST_VERSION_MODE_CHANGED", data);
};

const changeUnreadOnlyMode = ({ commit }, data) => {
    commit("UNREAD_ONLY_MODE_CHANGED", data);
};

const changeGroupGamesAndSources = ({ commit }, data) => {
    commit("GROUP_GAMES_AND_SOURCES_CHANGED", data);
};

const changePayersOnlyMode = ({ commit }, data) => {
    commit("PAYERS_ONLY_MODE_CHANGED", data);
};

const sendAnswer = ({ dispatch, commit, state }, data) => {
    commit("ADD_TEMPORARY_MESSAGE", data);

    createHttp(state.token).post(getGames()[0].url + "/chat/message", data).then(() => {
        dispatch("fetchUserCard", {
            userId: data.userId,
            dialogueId: state.dialogueId,
            stayOnCard: true
        });
        dispatch("fetchUnreadCount");
    }).catch(function (e) {
        console.log("sendAnswer error", e);
    });
};
// eslint-disable-next-line no-unused-vars
const removeMessage = ({ dispatch, commit, state }, data) => {
    createHttp(state.token).post(getGames()[0].url + "/chat/removemessage", data).then(() => {
        dispatch("fetchUserCard", {
            userId: data.userid,
            dialogueId: state.dialogueId
        });
        dispatch("fetchUnreadCount");
    });
};
// eslint-disable-next-line no-unused-vars
const editMessage = ({ dispatch, commit, state }, data) => {
    createHttp(state.token).post(getGames()[0].url + "/chat/editmessage", data).then(() => {
        dispatch("fetchUserCard", {
            userId: data.userid,
            dialogueId: state.dialogueId
        });
    });
};
// eslint-disable-next-line no-unused-vars
const glueDialogue = ({ dispatch, commit, state }, data) => {
    createHttp(state.token).post(getGames()[0].url + "/chat/gluedialogue", data).then(() => {
        state.dialogueId = undefined;
        dispatch("fetchUserCard", { userId: data.userid });
    });
};

const mark = ({ dispatch, state }, data) => {
    const dialoguesMap = utils.groupDialogueIdsByGames(data.dialogueIds);
    axios.all(getGames().filter((game) => dialoguesMap.has(game.name)).map(function (game) {
        return createHttp(state.token).put(game.url + "/chat/mark", {
            ids: dialoguesMap.get(game.name),
            mark: 1
        });
    })).then(() => {
        if (data.userId) {
            dispatch("fetchUserCard", {
                userId: data.userId,
                dialogueId: state.dialogueId
            });
        } else {
            dispatch("fetchDialogues");
        }
    });
};

const unmark = ({ dispatch, state }, data) => {
    const dialoguesMap = utils.groupDialogueIdsByGames(data.dialogueIds);
    axios.all(getGames().filter((game) => dialoguesMap.has(game.name)).map(function (game) {
        return createHttp(state.token).put(game.url + "/chat/mark", {
            ids: dialoguesMap.get(game.name),
            mark: 0
        });
    })).then(() => {
        if (data.userId) {
            dispatch("fetchUserCard", {
                userId: data.userId,
                dialogueId: state.dialogueId
            });
        } else {
            dispatch("fetchDialogues");
        }
    });
};

const removeUser = ({ dispatch, state }, userId) => {
    axios.all(getGames().map(function (game) {
        return createHttp(state.token).delete(game.url, {
            data: {
                id: userId
            }
        });
    })).then(() => {
        dispatch("fetchUserCard", {
            userId: userId,
            dialogueId: state.dialogueId
        });
    });
};

const addToBlackList = ({ dispatch, state }, userId) => {
    axios.all(getGames().map(function (game) {
        return createHttp(state.token).put(game.url + "/addtoblacklist/", {
            id: userId
        });
    })).then(() => {
        dispatch("fetchUserCard", {
            userId: userId,
            dialogueId: state.dialogueId
        });
    });
};

const androidRefund = ({ dispatch, state }, data) => {
    axios.all(getGames().map(function (game) {
        return createHttp(state.token).put(game.url + "/androidrefund/", data);
    })).then(() => {
        dispatch("fetchUserCard", {
            userId: data.userid,
            dialogueId: state.dialogueId
        });
    });
};

const setCheater = ({ dispatch, state }, userId) => {
    axios.all(getGames().map(function (game) {
        return createHttp(state.token).put(game.url + "/setcheater/", {
            id: userId
        });
    })).then(() => {
        dispatch("fetchUserCard", {
            userId: userId,
            dialogueId: state.dialogueId
        });
    });
};

const setSpammer = ({ dispatch, state }, data) => {
    axios.all(getGames().map(function (game) {
        return createHttp(state.token).put(game.url + "/chat/setspammer/", data);
    })).then(() => {
        dispatch("fetchUserCard", {
            userId: data.id,
            dialogueId: state.dialogueId
        });
    });
};

const addTag = ({ dispatch, state }, data) => {
    var gameUrl = config.games.filter(function (game) {
        return game.name === data.game;
    })[0].url;

    createHttp(state.token).put(gameUrl + "/chat/settag", data).then(() => {
        dispatch("fetchUserCard", {
            userId: data.userid,
            dialogueId: state.dialogueId
        });
        dispatch("fetchTags");
    });
};

const addTags = ({ dispatch, state }, data) => {
    const dialoguesMap = utils.groupDialogueIdsByGames(data.dialogueIds);
    axios.all(getGames().filter((game) => dialoguesMap.has(game.name)).map(function (game) {
        return createHttp(state.token).put(game.url + "/chat/settag", {
            ids: dialoguesMap.get(game.name),
            tag: data.tag,
            author: data.author
        });
    })).then(() => {
        dispatch("fetchDialogues");
        dispatch("fetchTags");
    });
};

const removeTag = ({ dispatch, state }, data) => {
    var gameUrl = config.games.filter(function (game) {
        return game.name === data.game;
    })[0].url;

    createHttp(state.token).put(gameUrl + "/chat/removetag", data).then(() => {
        if (data.fromUserCard) {
            dispatch("fetchUserCard", {
                userId: data.userid,
                dialogueId: state.dialogueId
            });
        } else {
            dispatch("fetchDialogues");
        }
        dispatch("fetchTags");
    });
};

const editTag = ({ dispatch, state }, data) => {
    axios.all(getGames().map(function (game) {
        return createHttp(state.token).put(game.url + "/chat/edittag", data);
    })).then(() => {
        if (data.userId) {
            dispatch("fetchUserCard", {
                userId: data.userId,
                dialogueId: state.dialogueId
            });
        } else {
            dispatch("fetchDialogues");
        }
        dispatch("fetchTags");
    });
};
// eslint-disable-next-line no-unused-vars
var login = function ({ dispatch, commit, state }, model) {
    createHttp(state.token).post(getGames()[0].url + "/auth/login", model).then(async (result) => {
        if (result.data.success) {
            commit("SET_TOKEN", result.data);
            await router.push("/");
        } else {
            alert(result.data);
        }
    });
};

const markAsRead = ({ dispatch, state }, data) => {
    const dialoguesMap = utils.groupDialogueIdsByGames(data.dialogueIds);
    axios.all(getGames().filter((game) => dialoguesMap.has(game.name)).map(function (game) {
        return createHttp(state.token).put(game.url + "/chat/markAsRead", {
            ids: dialoguesMap.get(game.name),
            read: 1
        });
    })).then(() => {
        if (data.userId) {
            dispatch("fetchUserCard", {
                userId: data.userId,
                dialogueId: state.dialogueId
            });
        } else {
            dispatch("fetchDialogues");
        }
        dispatch("fetchUnreadCount");
    });
};

const markAsUnread = ({ dispatch, state }, data) => {
    const dialoguesMap = utils.groupDialogueIdsByGames(data.dialogueIds);
    axios.all(getGames().filter((game) => dialoguesMap.has(game.name)).map(function (game) {
        return createHttp(state.token).put(game.url + "/chat/markAsRead", {
            ids: dialoguesMap.get(game.name),
            read: 0
        });
    })).then(() => {
        if (data.userId) {
            dispatch("fetchUserCard", {
                userId: data.userId,
                dialogueId: state.dialogueId
            });
        } else {
            dispatch("fetchDialogues");
        }
        dispatch("fetchUnreadCount");
    });
};
// eslint-disable-next-line no-unused-vars
const translate = ({ dispatch, commit, state }, data) => {
    var gameUrl = config.games.filter(function (game) {
        return game.name === data.game;
    })[0].url;

    createHttp(state.token).put(gameUrl + "/chat/translate", {
        sourceLang: data.sourceLang,
        lang: data.lang,
        text: data.message
    }).then(function (result) {
        commit("MESSAGE_TRANSLATED", {
            id: data.id,
            data: result.data
        });
        if (data.callback) {
            data.callback();
        }
    });
};
// eslint-disable-next-line no-unused-vars
const setStatus = ({ dispatch, commit, state }, data) => {
    var gameUrl = config.games.filter(function (game) {
        return game.name === data.game;
    })[0].url;

    createHttp(state.token).put(gameUrl + "/chat/setstatus", data).then(() => {
        if (state.zen && state.zen.active && state.zen.userIds.indexOf(data.userid) !== -1) {
            dispatch("zenCardProcessed", data);
        } else {
            dispatch("fetchUserCard", { userId: data.userid });
        }
    });
};
// eslint-disable-next-line no-unused-vars
const activateZen = ({ dispatch, commit, state }, data) => {
    var game = config.games.filter(function (game) {
        return game.name === data.game;
    })[0];

    if (!game) {
        return;
    }

    var gameUrl = game.url;

    createHttp(state.token).put(gameUrl + "/chat/zenmode/activate", data).then(async function (result) {
        var activationResult = result.data;
        if (!activationResult || !activationResult.userIds || activationResult.userIds.length === 0) {
            commit("SET_ZEN_STATE", {
                active: false,
                game: data.game,
                userIds: [],
                error: "No messages found for '" + data.game + "'" + (data.tags && data.tags.length > 0 ? " with tag '" + data.tags[0] + "'" : "") + ". Try to change parameters.",
                tags: data.tags
            });
        } else {
            commit("SET_ZEN_STATE", {
                active: true,
                game: data.game,
                initialUserIdsCount: activationResult.userIds.length,
                error: undefined,
                userIds: activationResult.userIds,
                tags: activationResult.zenTag ? [activationResult.zenTag] : []
            });

            await router.push({
                name: "UserCard",
                params: { folder: "Inbox:" + data.game, userId: activationResult.userIds[0] }
            });
        }
    });
};

const zenCardProcessed = async ({ dispatch, commit, state }, data) => {
    state.zen.userIds.splice(state.zen.userIds.indexOf(data.userid), 1);

    if (state.zen.userIds.length > 0) {
        commit("SET_ZEN_STATE", {
            active: true,
            game: data.game,
            userIds: state.zen.userIds,
            initialUserIdsCount: state.zen.initialUserIdsCount,
            payersOnly: state.zen.payersOnly,
            tags: state.zen.tags
        });

        await router.push({ name: "UserCard", params: { folder: "Inbox:" + data.game, userId: state.zen.userIds[0] } });

        dispatch("fetchZenTotalCount", data.game);
    } else {
        commit("SET_ZEN_STATE", {
            active: false, game: data.game, userIds: [], payersOnly: state.zen.payersOnly, tags: state.zen.tags
        });

        await router.push("/");
    }
};

var getGames = function () {
    var games = router.currentRoute.value.params.folder ? router.currentRoute.value.params.folder.split(":")[1].split(",") : ["all"];
    return config.games.filter(function (game) {
        return game.url !== undefined && (games[0] === "all" || games.indexOf(game.name) !== -1);
    });
};

export default {
    fetchDialogues,
    fetchUserCard,
    fetchUserSummary,
    fetchUnreadCount,
    fetchInboxCount,
    fetchTags,
    fetchZenTotalCount,

    login,
    search,
    sendAnswer,
    removeMessage,
    editMessage,

    mark,
    unmark,
    removeUser,
    addToBlackList,
    androidRefund,
    setCheater,
    setSpammer,
    markAsRead,
    markAsUnread,
    glueDialogue,
    translate,

    activateZen,
    zenCardProcessed,

    addTag,
    addTags,
    editTag,
    setStatus,
    removeTag,

    filterByTag,
    filterByStatus,
    filterByLanguage,
    filterBySource,
    filterByDate,
    filterByRespondingPerson,
    filterByEpisode,
    filterByLevel,
    changeWithAnswersMode,
    changeLatestVersionMode,
    changeUnreadOnlyMode,
    changeGroupGamesAndSources,
    changePayersOnlyMode
};
