/**
 * Created by Andrey Popov on 12/9/20.
 */

import { createStore } from "vuex";
import actions from "./actions";
import mutations from "./mutations";
import utils from "./utils";

export default createStore({
    state: {
        dialogues: [],
        selectedDialogueIds: [],
        userCard: null,
        userSummary: [],
        dialogueId: null,
        query: "",
        page: {
            selected: 0,
            size: 50,
            count: 1
        },
        count: {
            inbox: 0,
            inboxPayers: 0,
            unread: {}
        },
        tags: {},
        filter: utils.getFilterFromQuery(),
        token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
        respondingPerson: localStorage.getItem("respondingPerson") ? localStorage.getItem("respondingPerson") : "",
        uiState: localStorage.getItem("uiState") ? JSON.parse(localStorage.getItem("uiState")) : {
            sidebarCollapsed: true,
            sidebarGameSelected: false,
            userProfileCollapsed: false
        },
        zen: localStorage.getItem("zenParams") ? JSON.parse(localStorage.getItem("zenParams")) : {
            active: false,
            game: undefined,
            error: undefined,
            userIds: [],
            initialUserIdsCount: 0,
            payersOnly: true,
            tags: [],
            totalCount: 0
        },
        loading: false,
        disableGesture: false,
        translations: {},
        commonResponses: {
            loaded: false,
            ru: [],
            en: []
        }
    },
    getters: {
        isAuthenticated: (state) => state.token.length > 0
    },
    mutations,
    actions
});