<template>
    <div class="user-cell">
        <span class="user-names">
            <span v-if="!entry.isResponse && entry.respondingPersons && entry.respondingPersons.length > 0" :title="entry.respondingPersons" >{{truncateName(entry.respondingPersons)}}(S), </span>
            <span :title="entry.name + ' id:' + entry.userId">{{truncateName(entry.name)}}</span>
            <span v-if="entry.name && entry.name.length > 20"> .. </span>
            <span v-if="entry.isResponse" :title="entry.respondingPersons">, {{truncateName(entry.respondingPersons)}}(S)</span>
        </span>
        <span v-if="entry.total && entry.total > 1" class="messages-counter">{{entry.total}}</span>
    </div>
</template>

<script>
    export default {
        name: "dialogueusercell",
        props: {
            entry: {
                type: Object,
                required: true
            }
        },
        methods: {
            truncateName(str) {
                if (!str) {
                    return '';
                }

                return str.substr(0, 20);
            }
        }
    }
</script>

<style scoped>
    .user-cell {
        border-collapse: collapse;
        border-spacing: 0;
        cursor: pointer;
        user-select: none;
        empty-cells: show;
        line-height: 20px;
        overflow: hidden;
        -webkit-font-smoothing: antialiased;
        white-space: pre;
        align-items: baseline;
        display: flex;
        -webkit-box-flex: 1;
        flex-grow: 1;
        -webkit-box-ordinal-group: 1;
        order: 1;
        margin-bottom: -2px;
    }

    .user-names {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .messages-counter {
        font-size: .75rem;
        letter-spacing: .3px;
        color: #5f6368;
        margin-left: 4px;
    }

</style>