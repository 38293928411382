/**
* Created by Andrey Popov on 11/1/21.
*/

<template>
    <div class='number-input'>
        <div class="number-input-title">
            {{ title }}
        </div>
        <button
            @click='decreaseNumber'
            @mousedown='whileMouseDown(decreaseNumber)'
            @mouseup='clearTimer'
            @mouseleave='clearTimer'
            class='number-input-button'>-
        </button>
        <button
            @click='increaseNumber'
            @mousedown='whileMouseDown(increaseNumber)'
            @mouseup='clearTimer'
            @mouseleave='clearTimer'
            class='number-input-button right'>+
        </button>
        <input
            type='number'
            @keypress='validateInput'
            class='number-input-input'
            debounce='1000'
            v-model="value"
        />
    </div>
</template>

<script>
export default {
    name: 'NumberInput',
    data: function () {
        return {
            timer: null
        };
    },
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            default: 0
        },
        min: {
            default: 0,
            type: Number
        },
        max: {
            default: undefined,
            type: Number
        },
        step: {
            default: 1,
            type: Number
        },
        mouseDownSpeed: {
            default: 400,
            type: Number
        },
        integerOnly: {
            default: true,
            type: Boolean
        },
        title: {
            type: String
        }
    },
    methods: {
        clearTimer() {
            if (this.timer) {
                clearInterval(this.timer);
                this.timer = null;
            }
        },
        whileMouseDown(callback) {
            if (this.timer === null) {
                this.timer = setInterval(() => {
                    callback();
                }, this.mouseDownSpeed);
            }
        },
        increaseNumber() {
            this.value = parseFloat(this.value) + this.step;
        },
        decreaseNumber() {
            this.value = parseFloat(this.value) - this.step;
        },
        isInteger(evt) {
            evt = evt ? evt : window.event;
            let key = evt.keyCode || evt.which;
            key = String.fromCharCode(key);
            const regex = /[0-9]/;
            if (!regex.test(key)) {
                evt.returnValue = false;
                if (evt.preventDefault) evt.preventDefault();
            }
        },
        isNumber(evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        validateInput(evt) {
            if (this.integerOnly === true) {
                this.isInteger(evt);
            } else {
                this.isNumber(evt);
            }
        }
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', parseFloat(value));
            }
        }
    },
    watch: {
        modelValue: function (val) {
            if (val <= this.min) {
                this.value = this.min;
            }

            if (val > this.max) {
                this.value = this.max;
            }
        }
    }
};
</script>

<style scoped>
.number-input {
    margin: 4px 0;
}

.number-input *,
.number-input *::after,
.number-input *::before {
    box-sizing: border-box;
}

.number-input-input {
    border: 1px solid #ebebeb;
    float: right;
    font-size: 16px;
    height: 28px;
    margin: 0;
    outline: none;
    text-align: center;
    width: calc(100% - 56px);
}

.number-input-input::-webkit-outer-spin-button, .number-input-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.number-input-title {
    transition: background 0.5s ease;
    font-size: 16px;
    text-align: center;
}

.number-input-button {
    transition: background 0.5s ease;
    background: #387e90;
    border: 0;
    color: #fff;
    cursor: pointer;
    font-size: 20px;
    height: 28px;
    margin: 0;
    width: 28px;
}

.right {
    float: right;
}

.number-input-button:hover {
    background: #469eb5;
}

.number-input-button:focus {
    outline: 1px dashed #69b2c5;
    outline-offset: -5px;
}

</style>