/**
 * Created by Andrey Popov on 12/29/20.
 */

import moment from "moment";
import iso3311a2 from "iso-3166-1-alpha-2";
import config from "./config";

window.CRMClientApp = {};

cleverapps.dataLoader = {
    load: function () {
        return true;
    }
};

DataLoader.TYPES = { UNITS_LIBRARY: "", PAYMENTS_HISTORY: "", LIVES: "" };

cleverapps.user = {
    getFloatLevel: function () {
        return 7;
    }
};

const DIALOGUE_ID_SEPARATOR = "___DIALOGUE_ID_SEPARATOR___";

const parsePaymentsHistory = function (data) {
    data = JSON.parse(data);

    data.time = data.time.map(cleverapps.expandTime);
    if (data.price) {
        data.price = data.price.map(function (price) {
            return price / 100;
        });
    }

    return data;
};

const createDialogueId = function (game, dialogueId) {
    return game + DIALOGUE_ID_SEPARATOR + dialogueId;
};

const groupDialogueIdsByGames = function (dialogueIds) {
    var map = new Map();
    dialogueIds.forEach(function (dialogueId) {
        var game = dialogueId.split(DIALOGUE_ID_SEPARATOR)[0];
        dialogueId = dialogueId.split(DIALOGUE_ID_SEPARATOR)[1];
        if (map.has(game)) {
            map.get(game).push(dialogueId);
        } else {
            map.set(game, [dialogueId]);
        }
    });
    return map;
};

const formatDate = function (str, short) {
    if (short) {
        return moment(str).format("HH:mm DD MMM yyyy");
    }
    return moment(str).format("HH:mm DD MMM YYYY") + " (" + moment(str).fromNow() + ")";
};

const hasPropValues = function (userCard, property) {
    return userCard
        && userCard[property.collection]
        && userCard[property.collection].length > 0
        && (property.isAction || !property.path || userCard[property.collection][0][property.path.split(".")[0]] !== undefined);
};

const getPropValues = function (userCard, property) {
    return (userCard[property.collection] || []).map((propValue) => {
        var result = propValue;
        if (property.path) {
            if (property.path.indexOf(".") !== -1) {
                property.path.split(".").forEach(function (pathPart) {
                    result = JSON.parse(result[pathPart]);
                });
            } else {
                result = result[property.path] || "-";
            }
        }
        return result;
    });
};

const getSettingsPropValues = function (userCard, property) {
    var result = getPropValues(userCard, property);
    if (property.path === "sound" || property.path === "music") {
        result[0] = result[0] ? "on" : "off";
    }
    return result;
};

const getGameInfoPropValues = function (userCard, property) {
    var result = getPropValues(userCard, property);
    if (result.length === 0) {
        return result;
    }
    if (property.collection === "boosters") {
        var boostersObj = JSON.parse(result[0]);
        var strParts = [];
        Object.keys(boostersObj).forEach(function (key) {
            switch (key) {
                case "0": strParts.push("cell: " + boostersObj[key]); break;
                case "1": strParts.push("line: " + boostersObj[key]); break;
                case "2": strParts.push("color: " + boostersObj[key]); break;
                case "3": strParts.push("hint: " + boostersObj[key]); break;
                case "4": strParts.push("wildcard: " + boostersObj[key]); break;
                case "5": strParts.push("shuffle (combo): " + boostersObj[key]); break;
                case "6": strParts.push("moves: " + boostersObj[key]); break;
                case "7": strParts.push("multicolor: " + boostersObj[key]); break;
                case "8": strParts.push("brush: " + boostersObj[key]); break;
                case "9": strParts.push("discover: " + boostersObj[key]); break;
                case "10": strParts.push("magnet: " + boostersObj[key]); break;
                case "11": strParts.push("dice: " + boostersObj[key]); break;
                case "12": strParts.push("jokers: " + boostersObj[key]); break;
                case "13": strParts.push("highlight: " + boostersObj[key]); break;
                case "14": strParts.push("eye: " + boostersObj[key]); break;
                case "15": strParts.push("discover: " + boostersObj[key]); break;
                case "16": strParts.push("shuffle: " + boostersObj[key]); break;
                case "17": strParts.push("tile shuffle: " + boostersObj[key]); break;
                case "18": strParts.push("undo: " + boostersObj[key]); break;
                case "20": strParts.push("replace all: " + boostersObj[key]); break;
                case "21": strParts.push("bomb: " + boostersObj[key]); break;
                case "22": strParts.push("undo: " + boostersObj[key]); break;
                case "23": strParts.push("vacuum: " + boostersObj[key]); break;
            }
        });
        result[0] = strParts.join("\n");
    } else if (property.collection === "tempgoods") {
        var unlimObj = JSON.parse(result[0]);
        if (unlimObj["1"]) {
            unlimObj = unlimObj["1"];
            result[0] = "Buy Time: " + this.formatDate(new Date(unlimObj.buyTime), true) + "\nAction Time: " + unlimObj.actionTime / 60000 + " min";
            if (unlimObj.fpShowed) {
                result[0] += "\nPromotion shown";
            }
        }
    } else if (property.collection === "heroes") {
        var heroesObj = JSON.parse(result[0]);
        strParts = [];
        Object.keys(heroesObj).forEach(function (key) {
            switch (key) {
                case "a": strParts.push("blue: " + heroesObj[key]); break;
                case "b": strParts.push("green: " + heroesObj[key]); break;
                case "c": strParts.push("purple: " + heroesObj[key]); break;
                case "d": strParts.push("red: " + heroesObj[key]); break;
                case "e": strParts.push("yellow: " + heroesObj[key]); break;
                case "w": strParts.push("white: " + heroesObj[key]); break;
            }
        });
        result[0] = strParts.join("\n");
    } else if (property.type === "date") {
        result = formatDate(result, true);
    } else if (property.name === "Lives") {
        var lives = {};
        cleverapps.Lives.prototype.load.call(lives, JSON.parse(result));
        result = lives.amount + (lives.whenStart ? "\nwhen start: " + formatDate(lives.whenStart, true) : "");
    } else if (property.name === "Units Library") {
        UnitsLibrary.prototype.init = function () {
            this.codesByTabId = {};
            this.codesByTabId.default = [[], [], [], [], [], ["telly", "cinema"]];
        };
        cleverapps.config.type = "merge";
        var library = new UnitsLibrary("");
        UnitsLibrary.prototype.load.call(library, JSON.parse(result));
        var opened = Object.keys(library.opened).map(function (family, index) {
            return (index % 5 === 0 ? "\n" : " ") + family + ": " + Object.keys(library.opened[family]).filter((stage) => library.opened[family][stage]).join(" ");
        }).join("");
        var fresh = Object.keys(library.fresh).map(function (family, index) {
            return (index % 5 === 0 ? "\n" : " ") + family + ": " + Object.keys(library.fresh[family]).filter((stage) => library.fresh[family][stage]).join(" ");
        }).join("");

        result = "Opened" + opened + "\nFresh" + fresh + "\nHeroes \n" + JSON.stringify(library.heroes);
    } else if (property.name === "PaymentsHistory") {
        const history = parsePaymentsHistory(result);
        result = history.ids.map(function (id, index) {
            return id + " - " + formatDate(history.time[index], true);
        }).reverse().join("\n");
    }
    return result;
};

const classifyPayer = function (userCard) {
    if (!userCard || !userCard.paymentshistory || userCard.paymentshistory.length === 0) {
        return 0;
    }

    const history = parsePaymentsHistory(userCard.paymentshistory[0].data);

    const subsActive = userCard.subscriptions.some(function (subscription) {
        return Object.values(JSON.parse(subscription.data || "{}")).some(function (data) {
            return data.active;
        });
    });

    return cleverapps.PaymentsHistory.classify({
        ids: history.ids,
        time: history.time,
        price: history.price,
        subscription: subsActive,
        level: cleverapps.user.getFloatLevel()
    });
};

const getRealLevel = function (episodes, progress) {
    return cleverapps.Random.random(5 * 15, parseInt(episodes) * 15 - 1, progress) + 1;
};

const abTests = function (userId, projectName) {
    cleverapps.isLocalhost = function () {
        return false;
    };
    return Object.keys(cleverapps.ABTest.Tests).filter((testName) => {
        if (testName === "remote") {
            return false;
        }
        var test = cleverapps.ABTest.Tests[testName];
        return !test.available || !test.available.projectName || test.available.projectName.indexOf(projectName) !== -1;
    }).map(function (testName) {
        var group = cleverapps.ABTest.calcGroup(cleverapps.ABTest.Tests[testName], userId);
        return testName + " - " + String.fromCharCode(65 + group);
    });
};

const isMatch3 = function (game) {
    return ["riddles", "heroes", "runes", "adventure"].includes(game);
};

const isSolitaire = function (game) {
    return ["tripeaks", "spades"].includes(game);
};

const isTile3 = function (game) {
    return ["zenmatch", "tilehero"].includes(game);
};

const isBlocks = function (game) {
    return ["woodenblock", "tetris"].includes(game);
};

const hasSimpleMetha = function (game) {
    return ["crocword", "scramble", "magicwords", "tripeaks", "spades", "differences", "runes", "adventure"].includes(game);
};

const hasFarmMetha = function (game) {
    return ["heroes"].includes(game);
};

const hasHomeMetha = function (game) {
    return ["wordsoup"].includes(game);
};

const isMerge = function (game) {
    return ["mergecraft", "wondermerge", "fairy", "hustlemerge"].includes(game);
};

const noLives = function (game) {
    return ["crocword", "wordsoup", "scramble", "magicwords"].includes(game);
};

const noSoft = function (game) {
    return ["runes", "adventure", "crocword", "differences", "heroes", "klondike", "wordsoup", "magicwords", "olympics", "riddles", "spades", "tripeaks"].includes(game);
};

const getMergeLibrary = function (userCard) {
    var games = userCard.games;
    var unitsLibraries = userCard.unitsLibrary;
    var game = JSON.parse(games && games[0] && games[0].data || "{}");
    var unitsLibrary = game.unitsLibrary || JSON.parse(unitsLibraries && unitsLibraries[0] && unitsLibraries[0].data || "{}");

    var library = {};
    var opened = unitsLibrary.opened;

    if (!opened) {
        return library;
    }

    Object.keys(opened).forEach(function (code) {
        library[code] = UnitsLibrary.prototype.fromBit(opened[code]);
    });

    return library;
};

const getMergeFamilyPresent = function (userCard, family) {
    var library = this.getMergeLibrary(userCard);
    if (!library[family.code] || ["coinsplantchest", "rubiesplantchest", "energyplantchest"].includes(family.code)) {
        return;
    }
    var opened = library[family.code];
    var amount = 0;
    if (family.type === "resource") {
        delete opened[family.units.length - 2];
    }
    var stage = Math.max(...Object.keys(opened));

    if (family.type === "resource") {
        amount = opened[family.units.length - 1] ? 5 : 2;
    } else if (family.type === "fruit" && opened[3]) {
        stage = 3;
        amount = 1;
    } else if (["chest", "telly"].includes(family.type)) {
        amount = 2;
    } else if (family.type === "valuables") {
        amount = 4;
    } else if (["rubiesplant", "coinsplant", "energyplant", "magicplant"].includes(family.type)) {
        amount = 1;
    } else if (family.type === "hero") {
        amount = opened[family.units.length - 1] ? 3 : 2;
    }

    if (stage < 0 || amount <= 0) {
        return undefined;
    }

    while (family.units[stage].deleted || family.units[stage].unsuitableForGift) {
        stage--;
    }

    return { unit: { code: family.code, stage: stage, amount: amount } };
};

const isEventInElement = function (event, element) {
    var rect = element.getBoundingClientRect();
    var x = event.clientX;
    if (x < rect.left || x >= rect.right) {
        return false;
    }
    var y = event.clientY;
    if (y < rect.top || y >= rect.bottom) {
        return false;
    }
    return true;
};

const getElementCenter = function (element) {
    var rect = element.getBoundingClientRect();
    return {
        x: rect.left + rect.width / 2,
        y: rect.top + rect.height / 2
    };
};

const makeUnselectable = function (node, value) {
    if (node.nodeType === 1) {
        if (value) {
            node.classList.add("unselectable");
        } else {
            node.classList.remove("unselectable");
        }
    }
    var child = node.firstChild;
    while (child) {
        makeUnselectable(child, value);
        child = child.nextSibling;
    }
};

const listExpeditions = function (game) {
    return TravelBook.PAGES.filter(function (page) {
        if (page.id === "main") {
            return true;
        }

        if (isMerge(game) && (!page.available || !page.available.projectName || page.available.projectName.indexOf(game) !== -1)) {
            return true;
        }

        return false;
    }).map(function (page) {
        return page.id;
    });
};

const getExpeditionType = function (expedition) {
    return TravelBook.PAGES.find(function (page) {
        return page.id === expedition;
    }).missionType;
};

const getRewardUrl = function (gameName, reward) {
    const context = require.context("../assets/rewards", true, /\.png$/);
    const images = context.keys();

    if (reward === "hard" || reward === "gold") {
        reward = this.noSoft(gameName) ? "coin" : "ruby";
    }

    if (reward === "soft" || reward === "coins") {
        reward = "coin";
    }

    const paths = [this.isMerge(gameName) ? `./merge/${reward}.png` : `./${reward}.png`, "./present.png"];
    const image = paths.find((path) => images.includes(path));

    return context(image);
};

const getUnitUrl = function (gameName, code) {
    const context = require.context("../assets/units", true, /\.png$/);
    const images = context.keys();
    const paths = [`./${gameName}/${code}.png`, `./mergecraft/${code}.png`];
    const image = paths.find((path) => images.includes(path));

    return context(image);
};

const getFlagUrl = function (lang) {
    const context = require.context("../assets/flags", false, /\.svg$/);
    if (lang && config.languages.concat(["da", "no"]).indexOf(lang) !== -1) {
        return context("./" + lang + ".svg");
    }
};

const getCountryName = function (code) {
    return iso3311a2.getCountry(code) || "";
};

const getFilterFromQuery = function () {
    var searchParams = new URLSearchParams(window.location.hash.split("?")[1]);
    var filter = {
        byTag: [],
        byStatus: [],
        bySource: [],
        byRespondingPerson: [],
        unreadOnlyMode: false,
        withAnswers: false,
        byDate: {},
        byLanguage: [],
        groupGamesAndSources: false,
        payersOnlyMode: false,
        latestVersion: false,
        byEpisode: [],
        byLevel: []
    };

    if (searchParams.get("byTag")) {
        filter.byTag = searchParams.get("byTag").split(",");
    }
    if (searchParams.get("byStatus")) {
        filter.byStatus = searchParams.get("byStatus").split(",");
    }
    if (searchParams.get("bySource")) {
        filter.bySource = searchParams.get("bySource").split(",");
    }
    if (searchParams.get("byRespondingPerson")) {
        filter.byRespondingPerson = searchParams.get("byRespondingPerson").split(",");
    }
    if (searchParams.get("unreadOnlyMode")) {
        filter.unreadOnlyMode = true;
    }
    if (searchParams.get("withAnswers")) {
        filter.withAnswers = true;
    }
    if (searchParams.get("latestVersion")) {
        filter.latestVersion = true;
    }
    if (searchParams.get("byEpisode")) {
        filter.byEpisode = searchParams.get("byEpisode").split(",");
    }
    if (searchParams.get("byLevel")) {
        filter.byLevel = searchParams.get("byLevel").split(",");
    }
    if (searchParams.get("fromDate")) {
        filter.byDate.from = searchParams.get("fromDate");
    }
    if (searchParams.get("toDate")) {
        filter.byDate.to = searchParams.get("toDate");
    }

    return filter;
};

const updateQueryUsingFilter = function (filter) {
    var searchParams = new URLSearchParams();
    if (filter.byTag && filter.byTag.length > 0) {
        searchParams.set("byTag", filter.byTag);
    }
    if (filter.byStatus && filter.byStatus.length > 0) {
        searchParams.set("byStatus", filter.byStatus);
    }
    if (filter.bySource && filter.bySource.length > 0) {
        searchParams.set("bySource", filter.bySource);
    }
    if (filter.byRespondingPerson && filter.byRespondingPerson.length > 0) {
        searchParams.set("byRespondingPerson", filter.byRespondingPerson);
    }
    if (filter.unreadOnlyMode) {
        searchParams.set("unreadOnlyMode", true);
    }
    if (filter.withAnswers) {
        searchParams.set("withAnswers", true);
    }
    if (filter.latestVersion) {
        searchParams.set("latestVersion", true);
    }
    if (filter.byEpisode && filter.byEpisode.length > 0) {
        searchParams.set("byEpisode", filter.byEpisode);
    }
    if (filter.byLevel && filter.byLevel.length > 0) {
        searchParams.set("byLevel", filter.byLevel);
    }
    if (filter.byDate && filter.byDate.from) {
        searchParams.set("fromDate", filter.byDate.from);
    }
    if (filter.byDate && filter.byDate.to) {
        searchParams.set("toDate", filter.byDate.to);
    }

    var params = searchParams.toString();
    if (params.length > 0) {
        params = "?" + params;
    }

    window.location.hash = window.location.hash.split("?")[0] + params;
};

const hasQueryFilter = function () {
    return window.location.hash.split("?")[1] !== undefined;
};

export default {
    createDialogueId,
    groupDialogueIdsByGames,
    formatDate,
    hasPropValues,
    getPropValues,
    getSettingsPropValues,
    getGameInfoPropValues,
    classifyPayer,
    getRealLevel,
    abTests,
    getMergeLibrary,
    getMergeFamilyPresent,
    isEventInElement,
    getElementCenter,
    makeUnselectable,

    isMatch3,
    isSolitaire,
    isTile3,
    isBlocks,
    hasSimpleMetha,
    hasFarmMetha,
    hasHomeMetha,
    isMerge,
    noLives,
    noSoft,
    listExpeditions,
    getExpeditionType,

    getRewardUrl,
    getUnitUrl,
    getFlagUrl,
    getCountryName,

    getFilterFromQuery,
    updateQueryUsingFilter,
    hasQueryFilter
};
