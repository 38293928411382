<template>
  <li v-if="item.component && !isHidden">
    <component :is="item.component" v-bind="item.props"/>
  </li>
  <li v-else-if="item.header && !isHidden" class="sidebar-header" :class="item.class" v-bind="item.attributes">
    {{ item.header }}
  </li>
  <li v-else-if="!isHidden" :class="itemClass" @mouseover="onMouseOver" @mouseout="onMouseOut" v-on="(isCollapsed && isFirstLevel) ? { mouseenter: onMouseEnter, mouseleave: onMouseLeave} : {}">
    <SidebarMenuLink :item="item" :class="linkClass" v-bind="linkAttrs" @click="onLinkClick">
      <template v-if="isCollapsed && isFirstLevel && !item.noAnimation">
        <transition name="slide-animation">
          <div v-if="hover" class="sidebar-mobile-bg" :style="mobileItemBackgroundStyle"/>
        </transition>
      </template>
      <Icon class="item-icon" v-if="item.icon" :name="item.icon" :title="item.title"/>
      <div v-if="!item.noAnimation || !isFirstLevel" class="sidebar-title" :class="(isCollapsed && isFirstLevel) && !isMobileItem && 'sidebar-title_hidden'" :style="isMobileItem && mobileItemStyle">
        <span>{{ item.title }}</span>
        <div v-if="hasChild" class="sidebar-arrow" :class="{'sidebar-arrow_open' : show}">
          <slot name="dropdown-icon" v-bind="{ isOpen: show }"/>
        </div>
        <span v-if="item.count" class="unread" >{{item.count}}</span>
      </div>

    </SidebarMenuLink>
    <template v-if="hasChild">
      <transition :appear="isMobileItem" name="expand" @enter="onExpandEnter" @afterEnter="onExpandAfterEnter" @beforeLeave="onExpandBeforeLeave" @afterLeave="onExpandAfterLeave">
        <perfect-scrollbar v-if="show" class="sidebar-child" :class="isMobileItem && 'sidebar-child_mobile'" :style="isMobileItem && mobileItemDropdownStyle">
          <ul class="sidebar-dropdown">
            <sidebar-menu-item v-for="subItem in item.child" :key="subItem.id" :item="subItem" :level="level+1">
              <template #dropdown-icon="{ isOpen }">
                <slot name="dropdown-icon" v-bind="{ isOpen }"/>
              </template>
            </sidebar-menu-item>
          </ul>
        </perfect-scrollbar>
      </transition>
    </template>
  </li>
</template>

<script>
import { toRefs, inject, watch } from 'vue'
import useMenu from './use/usemenu'
import useItem from './use/useitem'

import SidebarMenuLink from './sidebarmenulink.vue'
import Icon from '../../controls/icon'

export default {
  name: 'SidebarMenuItem',
  components: {
    SidebarMenuLink,
    Icon
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    level: {
      type: Number,
      default: 1
    }
  },
  setup (props) {
    const sidebarProps = inject('sidebar-props')
    const { isCollapsed, mobileItemStyle, mobileItemDropdownStyle, mobileItemBackgroundStyle } = useMenu(sidebarProps)
    const {
      active,
      exactActive,
      show,
      hover,
      isFirstLevel,
      isHidden,
      hasChild,
      linkClass,
      linkAttrs,
      itemClass,
      isMobileItem,
      onLinkClick,
      onMouseOver,
      onMouseOut,
      onMouseEnter,
      onMouseLeave,
      onExpandEnter,
      onExpandAfterEnter,
      onExpandBeforeLeave,
      onExpandAfterLeave
    } = useItem(props)

    watch(() => active.value, () => {
      if (active.value) {
        show.value = true
      }
    }, {
      immediate: true
    })

    return {
      isCollapsed,
      active,
      exactActive,
      isMobileItem,
      mobileItemStyle,
      mobileItemDropdownStyle,
      mobileItemBackgroundStyle,
      show,
      hover,
      isFirstLevel,
      isHidden,
      hasChild,
      linkClass,
      linkAttrs,
      itemClass,
      onLinkClick,
      onMouseOver,
      onMouseOut,
      onMouseEnter,
      onMouseLeave,
      onExpandEnter,
      onExpandAfterEnter,
      onExpandBeforeLeave,
      onExpandAfterLeave
    }
  }
}
</script>
