<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container" :class="{'modal-container-wide':wide}">
                    <div class="modal-header">
                        <slot name="header">
                        </slot>
                    </div>

                    <div :style="{'overflow-y: auto':!isFilter}" class="modal-body">
                        <slot name="body">
                        </slot>
                    </div>

                    <div class="modal-footer">
                        <slot name="footer">
                            <button v-if="sendCallback && !isFilter" class="modal-set-button" @click="sent">
                                Send
                            </button>
                            <button v-if="isFilter" class="modal-set-button" @click="sent">
                                Apply
                            </button>
                            <button class="modal-cancel-button" @click="cancel">
                                Cancel
                            </button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "modal",
    methods: {
        sent() {
            this.sendCallback();
            this.$emit('close');
        },
        cancel() {
            this.cancelCallback && this.cancelCallback();
            this.$emit('close');
        }
    },
    props: {
        sendCallback: { type: Function },
        cancelCallback: { type: Function },
        wide: {type: Boolean},
        isFilter: {type: Boolean, default: false}
    }
}
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-container {
    width: 640px;
    margin: 0px auto;
    padding: 10px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
}

.modal-container-wide {
    width: 70%;
    max-height: 70%;
}

.modal-header h3 {
    margin-top: 0;
    color: #42b983;
}

.modal-body {
    max-height: 500px;
    min-height: 150px;
}

.modal-set-button {
    margin: 0 10px 0 0;
    width: 100px;
}

.modal-footer {
    margin-top: 10px;
}

.modal-cancel-button {
    display: inline-block;
    margin: 0 10px;
}

</style>