/**
 * Created by r4zi4l on 20.08.2021
 */

Families.initialize = function () {
    Families.initializeBase();

    Families.wood = FamiliesHelper.ResourceFamily(9, "wood", { deletedStage: 5, basicPrice: 7 });
    Families.stone = FamiliesHelper.ResourceFamily(10, "stone", { deletedStage: 5, basicPrice: 8 });

    Families.bear = FamiliesHelper.HeroFamily(7, { deletedStage: 4 });
    Families.knight = FamiliesHelper.HeroFamily(11, { deletedStage: 1 });
    Families.hunter = FamiliesHelper.HeroFamily(11, { deletedStage: 4 });

    delete Families.moonstone;
    delete Families.moonstonechest;
    delete Families.moonstonesource;
    delete Families.moonstonegenerator;

    Families.afterInitialize();
};

if (typeof cc === "undefined") {
    module.exports = Families;
}
