<template>
    <div class="dropdown" @mouseleave="mouseLeave" @mouseover="mouseOver" @mouseenter="mouseEnter" @click="toggleMenu">
        <slot></slot>
        <transition>
            <div v-if="value" class="dropdown-menu" @mouseleave="startTimer" @mouseenter="stopTimer" @click.stop ref="dropdown">
                <slot name="dropdown"></slot>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "DropdownButton",
    props: {
        hover_time: {
            type: Number,
            default: 100
        },
        hover_timeout: {
            type: Number,
            default: 10
        },
        transition: {
            type: String,
            default: '',
        }
    },
    data () {
        return {
            hovering: false,
            top: false,
            value: false
        }
    },
    destroyed () {
        document.body.removeEventListener('click', this.closeMenu)
    },
    methods: {
        mouseEnter () {
            this.stopTimer()
            if (this.hover_time > 0 && !this.value) {
                this.hoverOpenTimer = setTimeout(() => {
                    this.value = true;
                    this.hovering = true
                    setTimeout(() => {
                        this.hovering = false
                    }, this.hover_timeout);
                }, this.hover_time)
            }
            if (!this.value && this.hover_time === 0) {
                this.hovering = true
                setTimeout(() => {
                    this.hovering = false
                }, this.hover_timeout);

                this.value = true;
            }
        },
        mouseLeave () {
            if (!this.hoverTimer) {
                this.startTimer()
            }
            if (this.hover_time > 0) {
                clearTimeout(this.hoverOpenTimer)
            }
        },
        mouseOver () {
            this.stopTimer()
        },
        closeMenu ($event) {
            if (!$event || !this.$el.contains($event.target)) {
                if (this.value) {
                    this.value = false;
                }
            }
        },
        toggleMenu () {
            if (this.hovering) {
                return;
            }

            if (this.value) {
                return;
            }

            this.value = !this.value;
        },
        stopTimer () {
            clearTimeout(this.hoverTimer);
            this.hoverTimer = null;
        },
        startTimer () {
            this.hoverTimer = setTimeout(this.closeMenu, this.hover_timeout);
        }
    },
    watch: {
        value (v) {
            if (v) {
                let vm = this
                this.top = false
                this.$nextTick(() => {
                        let rect = vm.$refs.dropdown.getBoundingClientRect()
                        let window_height = (window.innerHeight || document.documentElement.clientHeight)
                        this.top = (rect.bottom > window_height) && (rect.top >= rect.height)
                    }
                )
            }
        }
    }
}
</script>

<style scoped>
.dropdown {
    display: inline-block;
    position: relative;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    min-width: 205px;
    padding: .5rem 0;
    font-size: 1rem;
    margin: -4px 0 0 10px;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
}

</style>