/**
 * Created by Andrey Popov on 12/16/20.
 */

import utils from "./utils";

const DIALOGUES_FETCHED = (state, dialogues) => {
    state.loading = false;
    state.selectedDialogueIds = [];
    state.dialogueId = undefined;
    state.dialogues = dialogues;
};

const DIALOGUES_SELECT_DIALOGUE = (state, dialogueId) => {
    const index = state.selectedDialogueIds.indexOf(dialogueId);
    if (index > -1) {
        state.selectedDialogueIds.splice(index, 1);
    } else {
        state.selectedDialogueIds.push(dialogueId);
    }
};

const DIALOGUES_SELECT_ALL = (state) => {
    state.selectedDialogueIds = state.dialogues.map((t) => utils.createDialogueId(t.game, t.dialogueId));
};

const DIALOGUES_SELECT_NONE = (state) => {
    state.selectedDialogueIds = [];
};

const DIALOGUES_FETCHING = (state) => {
    state.loading = true;
};

const USER_CARD_FETCHING = (state, showLoading) => {
    state.loading = showLoading;
    state.disableGesture = true;
};

const USER_CARD_FETCHED = (state, userCard) => {
    state.loading = false;
    state.userCard = userCard;
    state.dialogueId = userCard.dialogueId;

    setTimeout(function () {
        state.disableGesture = false;
    }, 1500);
};

const USER_SUMMARY_FETCHED = (state, userSummary) => {
    state.userSummary = userSummary;
};

const SEARCH_QUERY = (state, query) => {
    state.page.selected = 0;

    state.query = query;
};

const CHANGE_FILTER_DATE = (state, data) => {
    state.page.selected = 0;

    state.filter.byDate = data || {};

    utils.updateQueryUsingFilter(state.filter);
};

const CHANGE_FILTER_TAG = (state, data) => {
    state.page.selected = 0;

    if (data.tagToAdd && state.filter.byTag.indexOf(data.tagToAdd) === -1) {
        state.filter.byTag.push(data.tagToAdd);
    }

    if (data.toRemove) {
        var index = state.filter.byTag.indexOf(data.toRemove);
        if (index !== -1) {
            state.filter.byTag.splice(index, 1);
        }
    }

    if (data.clear) {
        state.filter.byTag = [];
    }

    utils.updateQueryUsingFilter(state.filter);
};

const CHANGE_FILTER_STATUS = (state, data) => {
    state.page.selected = 0;

    if (data.status && state.filter.byStatus.indexOf(data.status) === -1) {
        state.filter.byStatus.push(data.status);
    }

    if (data.toRemove) {
        var index = state.filter.byStatus.indexOf(data.toRemove);
        if (index !== -1) {
            state.filter.byStatus.splice(index, 1);
        }
    }

    if (data.clear) {
        state.filter.byStatus = [];
    }

    utils.updateQueryUsingFilter(state.filter);
};

const CHANGE_FILTER_SOURCE = (state, data) => {
    state.page.selected = 0;

    if (data.source && state.filter.bySource.indexOf(data.source) === -1) {
        state.filter.bySource.push(data.source);
    }

    if (data.toRemove) {
        var index = state.filter.bySource.indexOf(data.toRemove);
        if (index !== -1) {
            state.filter.bySource.splice(index, 1);
        }
    }

    if (data.clear) {
        state.filter.bySource = [];
    }

    utils.updateQueryUsingFilter(state.filter);
};

const CHANGE_FILTER_RESPONDING_PERSON = (state, data) => {
    state.page.selected = 0;

    if (!state.filter.byRespondingPerson) {
        state.filter.byRespondingPerson = [];
    }

    if (data.person && state.filter.byRespondingPerson.indexOf(data.person) === -1) {
        state.filter.byRespondingPerson.push(data.person);
    }

    if (data.toRemove) {
        var index = state.filter.byRespondingPerson.indexOf(data.toRemove);
        if (index !== -1) {
            state.filter.byRespondingPerson.splice(index, 1);
        }
    }

    if (data.clear) {
        state.filter.byRespondingPerson = [];
    }

    utils.updateQueryUsingFilter(state.filter);
};

const CHANGE_FILTER_LANGUAGE = (state, data) => {
    state.page.selected = 0;

    if (!state.filter.byLanguage) {
        state.filter.byLanguage = [];
    }

    if (data.language && state.filter.byLanguage.indexOf(data.language) === -1) {
        state.filter.byLanguage.push(data.language);
    }

    if (data.toRemove) {
        var index = state.filter.byLanguage.indexOf(data.toRemove);
        if (index !== -1) {
            state.filter.byLanguage.splice(index, 1);
        }
    }

    if (data.clear) {
        state.filter.byLanguage = [];
    }

    utils.updateQueryUsingFilter(state.filter);
};

const CHANGE_FILTER_LEVEL = (state, data) => {
    state.page.selected = 0;

    if (!state.filter.byLevel) {
        state.filter.byLevel = [];
    }

    if (data.level && state.filter.byLevel.indexOf(data.level) === -1) {
        state.filter.byLevel.push(data.level);
    }

    if (data.toRemove) {
        var index = state.filter.byLevel.indexOf(data.toRemove);
        if (index !== -1) {
            state.filter.byLevel.splice(index, 1);
        }
    }

    if (data.clear) {
        state.filter.byLevel = [];
    }

    utils.updateQueryUsingFilter(state.filter);
};

const CHANGE_FILTER_EPISODE = (state, data) => {
    state.page.selected = 0;

    if (!state.filter.byEpisode) {
        state.filter.byEpisode = [];
    }

    if (data.episode && state.filter.byEpisode.indexOf(data.episode) === -1) {
        state.filter.byEpisode.push(data.episode);
    }

    if (data.toRemove) {
        var index = state.filter.byEpisode.indexOf(data.toRemove);
        if (index !== -1) {
            state.filter.byEpisode.splice(index, 1);
        }
    }

    if (data.clear) {
        state.filter.byEpisode = [];
    }

    utils.updateQueryUsingFilter(state.filter);
};

const UNREAD_COUNT_FETCHED = (state, unreadCount) => {
    state.count.unread = unreadCount;
};

const INBOX_COUNT_FETCHED = (state, inboxCount) => {
    state.count.inbox = inboxCount;
};

const ZEN_TOTAL_COUNT_FETCHED = (state, count) => {
    state.zen.totalCount = count;
};

const INBOX_COUNT_FETCHED_PAYERS = (state, inboxCount) => {
    state.count.inboxPayers = inboxCount;
};

const TAGS_FETCHED = (state, tags) => {
    state.tags = tags;
};

const CHANGE_PAGE = (state, inc) => {
    if (inc) {
        state.page.selected++;
    } else {
        state.page.selected--;
    }
};

const MESSAGE_TRANSLATED = (state, data) => {
    state.translations[data.id] = data.data;
};

const ADD_TEMPORARY_MESSAGE = (state, data) => {
    data.isResponse = 1;
    data.isTmp = true;
    state.userCard.messages.push(data);
};

const WITH_ANSWERS_MODE_CHANGED = (state, data) => {
    if (data !== undefined) {
        state.filter.withAnswers = data;
    } else {
        state.filter.withAnswers = !state.filter.withAnswers;
    }
};

const LATEST_VERSION_MODE_CHANGED = (state, data) => {
    if (data !== undefined) {
        state.filter.latestVersion = data;
    } else {
        state.filter.latestVersion = !state.filter.latestVersion;
    }
};

const UNREAD_ONLY_MODE_CHANGED = (state, data) => {
    if (data !== undefined) {
        state.filter.unreadOnlyMode = data;
    } else {
        state.filter.unreadOnlyMode = !state.filter.unreadOnlyMode;
    }
};

const GROUP_GAMES_AND_SOURCES_CHANGED = (state, data) => {
    if (data !== undefined) {
        state.filter.groupGamesAndSources = data;
    } else {
        state.filter.groupGamesAndSources = !state.filter.groupGamesAndSources;
    }
};

const PAYERS_ONLY_MODE_CHANGED = (state, data) => {
    if (data !== undefined) {
        state.filter.payersOnlyMode = data;
    } else {
        state.filter.payersOnlyMode = !state.filter.payersOnlyMode;
    }
};

const SET_TOKEN = (state, data) => {
    state.token = data.token;
    state.respondingPerson = data.respondingPerson;
    localStorage.setItem("token", data.token);
    localStorage.setItem("respondingPerson", data.respondingPerson);
};

const COMMON_RESPONSES_FETCHED = (state, data) => {
    state.commonResponses.loaded = true;
    state.commonResponses.en = data.en;
    state.commonResponses.ru = data.ru;
};

const SET_UI_STATE = (state, data) => {
    state.uiState = data;
    localStorage.setItem("uiState", JSON.stringify(data));
};

const SET_ZEN_STATE = (state, data) => {
    state.zen = data;
    var dataClone = JSON.parse(JSON.stringify(data));
    dataClone.error = undefined;
    localStorage.setItem("zenParams", JSON.stringify(dataClone));
};

export default {
    DIALOGUES_FETCHING,
    DIALOGUES_FETCHED,
    DIALOGUES_SELECT_DIALOGUE,
    DIALOGUES_SELECT_ALL,
    DIALOGUES_SELECT_NONE,
    USER_CARD_FETCHING,
    USER_CARD_FETCHED,
    USER_SUMMARY_FETCHED,
    SEARCH_QUERY,
    UNREAD_COUNT_FETCHED,
    INBOX_COUNT_FETCHED,
    INBOX_COUNT_FETCHED_PAYERS,
    ZEN_TOTAL_COUNT_FETCHED,
    TAGS_FETCHED,
    CHANGE_PAGE,
    MESSAGE_TRANSLATED,
    ADD_TEMPORARY_MESSAGE,
    SET_TOKEN,
    CHANGE_FILTER_TAG,
    CHANGE_FILTER_STATUS,
    CHANGE_FILTER_SOURCE,
    CHANGE_FILTER_RESPONDING_PERSON,
    CHANGE_FILTER_DATE,
    CHANGE_FILTER_LANGUAGE,
    CHANGE_FILTER_LEVEL,
    CHANGE_FILTER_EPISODE,
    WITH_ANSWERS_MODE_CHANGED,
    LATEST_VERSION_MODE_CHANGED,
    UNREAD_ONLY_MODE_CHANGED,
    GROUP_GAMES_AND_SOURCES_CHANGED,
    PAYERS_ONLY_MODE_CHANGED,
    COMMON_RESPONSES_FETCHED,
    SET_UI_STATE,
    SET_ZEN_STATE
};
