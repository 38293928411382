<template>
    <div class="overlay" v-if="elementsVisible"></div>
    <Icon id="gestureCard" class="gesture-card" :name="'gesturecard'" :style="{ transform: 'scale(' + cardScale.value + ') translateX(' + position.x + 'px) translateY(' + position.y + 'px)' }"/>
    <div class="gesture-statuses" v-if="elementsVisible">
        <div class="left-border" :class="{'highlight':(gestureDirection === 'left')}">
            <Icon class="icon-in-border" :name="'postpone' + (gestureDirection === 'left' ? '_active' : '')"/>
        </div>
        <div class="right-border" :class="{'highlight':(gestureDirection === 'right')}">
            <Icon class="icon-in-border" :name="'resolve' + (gestureDirection === 'right' ? '_active' : '')"/>
        </div>
        <div class="up-border" :class="{'highlight':(gestureDirection === 'up')}" >
            <Icon class="icon-in-border" :name="'skip' + (gestureDirection === 'up' ? '_active' : '')"/>
        </div>
        <div class="down-border unselectable" :class="{'highlight':(gestureDirection === 'down')}" :style="{ transform: 'scale(' + cardScale.value + ')}'}">
            CANCEL
        </div>
    </div>
</template>

<script>
import Icon from '@/controls/icon.vue';
import TinyGesture from 'tinygesture';
import { useSpring } from 'vue-use-spring';
import _ from "lodash";
import utils from "@/utils.js";

export default {
    name: "Gesture",
    components: {
        Icon
    },
    data() {
        return {
            active: false,
            elementsVisible: false,
            direction: undefined,
            cardScale: useSpring({value: 0.3} ),
            position: useSpring({ x: 0, y: 0 }),
            opacity: useSpring({value: 1} )
        }
    },
    mounted() {
        const gestureTarget = document.getElementById('gestureTarget');
        if (!gestureTarget) {
            return;
        }

        var gestureCard = document.getElementById("gestureCard");
        if (gestureCard) {
            gestureCard.style.visibility = 'hidden';
        }

        var activateGesture = function (event) {
            if (this.active || window.getSelection().toString().length !== 0 || this.$store.state.disableGesture) {
                return;
            }

            this.opacity.value = 0.1;
            this.cardScale.value = 1;
            this.active = true;
            this.elementsVisible = true;
            this.direction = undefined;

            gestureCard.style.left = event.x - 75 + "px";
            gestureCard.style.top = event.y - 75 + "px";

            gestureCard.style.visibility = 'visible';

            utils.makeUnselectable(gestureTarget, true);
        }.bind(this);

        var calcDirection = function (event, end) {
            var leftTarget = document.getElementsByClassName("left-border")[0];
            if (leftTarget && utils.isEventInElement(event, leftTarget)) {
                return 'left';
            }
            var rightTarget = document.getElementsByClassName("right-border")[0];
            if (rightTarget && utils.isEventInElement(event, rightTarget)) {
                return 'right';
            }
            var upTarget = document.getElementsByClassName("up-border")[0];
            if (upTarget && utils.isEventInElement(event, upTarget)) {
                return 'up';
            }
            var upTarget = document.getElementsByClassName("down-border")[0];
            if (upTarget && utils.isEventInElement(event, upTarget)) {
                return 'down';
            }

            if (!end) {
                return undefined;
            }

            if (Math.abs(this.gesture.touchStartX - this.gesture.touchEndX) > Math.abs(this.gesture.touchStartY - this.gesture.touchEndY)) {
                if (Math.abs(this.gesture.touchStartX - this.gesture.touchEndX) > 100) {
                    return this.gesture.touchStartX > this.gesture.touchEndX ? 'left' : 'right';
                }
            } else if (this.gesture.touchStartY - this.gesture.touchEndY > 100) {
                return 'up';
            }
            return 'down';
        }.bind(this);

        this.gesture = new TinyGesture(gestureTarget, {
            mouseSupport: true,
            threshold: () => 10,
            disregardVelocityThreshold: () => 500,
            velocityThreshold: 10,
        });
        this.gesture.on('panmove', _.throttle(function(event) {

            if (event.target && event.target.nodeType !== 3 && !this.active &&
                  (event.target.classList.contains('message-content') ||
                   event.target.classList.contains('caption-message-body') ||
                   event.target.classList.contains('container-message-display'))) {
                activateGesture(event);
            }

            if (!this.active) {
                return;
            }

            this.gestureDirection = calcDirection(event);
            this.position.x = this.gesture.touchMoveX;
            this.position.y = this.gesture.touchMoveY;
        }.bind(this), 20));

        this.gesture.on('panend', event => {
            if (!this.active) {
                return;
            }

            this.active = false;
            utils.makeUnselectable(gestureTarget, false);
            this.gestureDirection = calcDirection(event, true);

            if (this.gestureDirection &&
                Math.max(Math.abs(this.gesture.touchStartX - this.gesture.touchEndX), Math.abs(this.gesture.touchStartY - this.gesture.touchEndY)) > 50) {
                this.moveCard(this.gestureDirection);
            } else {
                this.opacity.value = 1;
                this.cardScale.value = 0.3;
                this.position.x = 0;
                this.position.y = 0;

                setTimeout(function () {
                    gestureCard.style.visibility = 'hidden';
                    this.elementsVisible = false;
                }.bind(this), 300);
            }
        });
    },
    unmounted() {
        if (this.gesture) {
            this.gesture.off('panmove');
            this.gesture.off('panend');
        }
    },
    computed: {
        gestureDirection: {
            get: function() {
                return this.direction;
            },
            set: function(value) {
                this.direction = value;
            }
        }
    },
    methods: {
        moveCard(direction) {
            var status = '';
            var center = utils.getElementCenter(document.getElementsByClassName(direction + "-border")[0]);

            if (direction === 'left') {
                status = 'pending';
                this.position.x = (center.x - window.innerWidth/2) - 100;
            } else if (direction === 'right') {
                status = 'resolved';
                this.position.x = (center.x - window.innerWidth/2) + 100;
            } else if (direction === 'up') {
                status = 'no answer needed';
                this.position.y = (center.y - window.innerHeight/2) - 100;
            } else if (direction === 'down') {
                this.position.y = (center.y - window.innerHeight/2) + 80;
            }

            setTimeout(function () {
                if (direction !== 'down') {
                    this.$store.dispatch('setStatus', {
                        status: status,
                        userid: this.$route.params.userId,
                        dialogueid: this.$store.state.dialogueId,
                        game: this.$route.params.folder.split(':')[1],
                        person: this.$store.state.respondingPerson
                    });
                }

                this.cardScale.value = 0.3;
                var gestureCard = document.getElementById("gestureCard");
                if (gestureCard) {
                    gestureCard.style.visibility = 'hidden';
                }
                this.elementsVisible = false;
            }.bind(this), 500);

            setTimeout(function () {
                this.position.x = 0;
                this.position.y = 0;
                this.opacity.value = 1;
            }.bind(this), 800);
        }
    }
}
</script>

<style scoped>
.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.2);
    z-index: 2;
    cursor: pointer;
}

.gesture-statuses {
    height: 97%;
    width: 100%;
    position: absolute;
    z-index: 1000;
    animation: fadein 0.8s;
    -moz-animation: fadein 0.8s;
    -webkit-animation: fadein 0.8s;
    -o-animation: fadein 0.8s;
}

.left-border {
    width: 30vw;
    height: 35vh;
    bottom: 0;
    top:0;
    margin-top: auto;
    margin-bottom: auto;
    left: 5px;
    z-index: 3001;
    position: absolute;
    border: 4px dashed gray;
}
.right-border {
    width: 30vw;
    height: 35vh;
    bottom: 0;
    top:0;
    margin-top: auto;
    margin-bottom: auto;
    right: 5px;
    z-index: 3001;
    position: absolute;
    border: 4px dashed gray;
}
.up-border {
    width: 30vw;
    height: 35vh;
    top: 5px;
    z-index: 3001;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    border: 4px dashed gray;
}
.down-border {
    width: 80vw;
    height: 8vh;
    bottom: 5px;
    z-index: 3001;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    border: 4px dashed gray;
    text-align: center;
    font-size: 60px;
    padding-top: 5vh;
    font-weight: 900;
}

.highlight {
    color: #00B7FF;
    border-color: #00B7FF;
}

.icon-in-border {
    position: absolute;
    top: 0; bottom: 0; right: 0; left: 0;
    margin: auto;
}

.gesture-card {
    position: absolute;
    z-index: 3002;
    animation: fadein 0.4s;
    -moz-animation: fadein 0.4s;
    -webkit-animation: fadein 0.4s;
    -o-animation: fadein 0.4s;
}

@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-o-keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity: 1;
    }
}

</style>