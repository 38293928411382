/**
 * Created by andrey on 03.01.2022.
 */

module.exports = {
    context: {},

    getContext: function () {
        return this.context;
    },

    getConfig: function () {
        return this.context.configData || {};
    },

    setContext: function (context) {
        this.context = context;
    },

    getProducts: function () {
        return this.context.products || {};
    },

    getName: function () {
        return this.context.name;
    }
};