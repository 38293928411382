<template>
<span
    class="check"
    :class="{
    'some-selected': someSelected && !selected,
    'selected': selected,
  }"
    @click.stop.prevent="onChange"
></span>
</template>

<script>
export default {
    name: 'CheckBox',
    props: ['selected', 'someSelected', 'onChange']
}
</script>

<style scoped>
.check {
    float: left;
    height: 11px;
    width: 11px;
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(155, 155, 155, 0.57);
    margin: 1px 10px 0 0;
    position: relative;
}
.check:hover {
    box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.1);
}
.check:after {
    display: none;
    content: "";
    position: absolute;
    height: 9px;
    top: 1px;
    width: 12px;
    background: url(../../assets/icons/checkmark.png);
}

.selected, .some-selected {
    background: rgba(255, 255, 255, 0.7);
}
.selected:after, .some-selected:after {
    display: block;
}
.some-selected:after {
    background: url(../../assets/icons/checkmark-partial.png);
    height: 20px;
    width: 20px;
    top: -5px;
    left: -5px;
}
</style>