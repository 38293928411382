/**
* Created by Andrey Popov on 11/1/21.
*/

<template>
    <perfect-scrollbar ref="chatMessages"  id='chatMessagesId' class="container-message-display">
        <div v-for="message in messages">
            <ChatMessage :message="message" :userLang="userLang" @edit-message="editMessage"/>
        </div>
    </perfect-scrollbar>

    <perfect-scrollbar :options="{suppressScrollY:true}" class="templates-display">
        <div v-for="(template, index) in templates">
            <MessageTemplate :template="template" :top3="index < 3" :top="Boolean(template.relevance && template.relevance > 200)" @select-template="selectMessageTemplate"/>
        </div>
    </perfect-scrollbar>
    <MessageManager @text-changed="onTextChanged" ref="messageManager"></MessageManager>
</template>
<script>

import ChatMessage from '@/components/chat/chatmessage.vue';
import MessageTemplate from '@/components/chat/messagetemplate.vue';
import MessageManager from '@/components/chat/messagemanager.vue';
const { GoogleSpreadsheet } = require('google-spreadsheet');

export default {
    name: 'chat',
    components: {
        MessageManager,
        ChatMessage,
        MessageTemplate
    },
    created() {
        if (!this.$store.state.commonResponses.loaded) {
            setTimeout(function () {
                this.loadCommonResponses();
            }.bind(this), 10000);

        }
    },
    mounted() {
        window.addEventListener("resize", this.adjustChatHeight);
        window.addEventListener("scroll", this.adjustChatHeight);
        this.adjustChatHeight();
    },
    unmounted() {
        window.removeEventListener("resize", this.adjustChatHeight);
        window.removeEventListener("scroll", this.adjustChatHeight);
    },
    data() {
        return {
            tabs: [{ title: 'Chat', value: 'chat'}],
            currentTab: 'chat',
            templatesSearchQuery: '',
            lastUserMessage: ''
        };
    },
    computed: {
        messages() {
            if (!this.$store.state.userCard || !this.$store.state.userCard.messages) {
                return [];
            }

            setTimeout(() => {
                const container = this.$refs.chatMessages;
                if (container) {
                    container.$el.scrollTop = container.$el.scrollHeight;
                }
            });

            var messages = [];
            var handledDialogs = [];
            var tags = this.$store.state.userCard.tags;
            this.lastUserMessage = '';
            this.$store.state.userCard.messages.forEach(function (message) {
                if (handledDialogs.indexOf(message.dialogueId) === -1 && !message.isTmp) {
                    var dialogueCaption = "Status: " + message.status;
                    var dialogueTags = tags.filter(function (tag) {
                        return tag.dialogueId === message.dialogueId;
                    }).map(function (tag) {
                        return tag.tag;
                    });
                    if (dialogueTags.length > 0) {
                        dialogueCaption += ". Tags: " + dialogueTags.join(",");
                    }
                    messages.push({
                        message: dialogueCaption,
                        isCaption: true
                    });
                    handledDialogs.push(message.dialogueId);
                }

                if (!message.isResponse) {
                    this.lastUserMessage = (message.translation || '').toLowerCase();
                }

                messages.push(message);
            }.bind(this));

            return messages;
        },
        userCard() {
            if (!this.$store.state.userCard) {
                return {};
            }

            return this.$store.state.userCard;
        },
        userLang() {
            var userLanguage = this.userCard.settings && this.userCard.settings.length > 0 ? this.userCard.settings[0].language : undefined;
            if (this.userCard.translateLanguage) {
                userLanguage = this.userCard.translateLanguage;
            }

            return userLanguage || 'no';
        },
        tags() {
            if (!this.userCard.tags) {
                return [];
            }

            return this.userCard.tags.filter(function (tag) {
                return tag.dialogueId == this.userCard.dialogueId;
            }.bind(this)).map(function (tag) {
                return tag.tag;
            });
        },
        templates() {
            if (!this.$store.state.commonResponses.loaded) {
                return [];
            }



            var commonTemplates = this.userLang === 'ru' ?
                this.$store.state.commonResponses.ru :
                this.$store.state.commonResponses.en;

            if (!this.templateSorted) {
                commonTemplates.sort(function (a, b) {
                    return this.updateAndReturnTemplateRelevance(b) - this.updateAndReturnTemplateRelevance(a);
                }.bind(this));
            }

            this.templateSorted = true;

            return commonTemplates;
        }
    },
    methods: {
        editMessage(messageText, messageId) {
            this.$refs.messageManager.editMessage(messageText, messageId);
        },
        selectMessageTemplate(messageText) {
            this.$refs.messageManager.editMessage(messageText);
        },
        onTextChanged(text) {
            this.templatesSearchQuery = text;
        },
        adjustChatHeight() {
            if (this.adjustInProgress) {
                return;
            }
            this.adjustInProgress = true;

            var chatMessagesElement = document.getElementById("chatMessagesId");

            var getBottom = function() {
                return window.innerHeight - chatMessagesElement.getBoundingClientRect().bottom;
            };

            var height = 20;
            chatMessagesElement.style.height = height +  "%";
            var chatMessagesBottom = getBottom();
            while (chatMessagesBottom > 230) {
                height++;
                chatMessagesElement.style.height = height +  "%";
                chatMessagesBottom = getBottom();
            }

            setTimeout(function () {
                this.adjustInProgress = false;

            }.bind(this), 1000);
        },
        updateAndReturnTemplateRelevance(template) {
            template.relevance = Math.min(parseInt(template.frequency), 199);

            this.tags.forEach(function (tag) {
                template.relevance += (template.tags || '').toLowerCase().indexOf(tag.toLowerCase()) === -1 ? 0 : 50;
            }.bind(this));

            (template.keywords || '').split(',').forEach(function (keyword) {
                if (keyword.length > 0 && this.lastUserMessage.indexOf(keyword.trim().toLowerCase()) !== -1) {
                    template.relevance += 100;
                }
            }.bind(this));

            if (this.templatesSearchQuery.length > 2 && this.templatesSearchQuery.length < 40) {
                var searchQueryWords = this.templatesSearchQuery.toLowerCase().split(' ').filter(w => w.length > 2);
                for (var wordIndex = 0; wordIndex < searchQueryWords.length; wordIndex++) {
                    if (template.message.toLowerCase().indexOf(searchQueryWords[wordIndex]) !== -1) {
                        template.relevance += 200;
                    }
                }
            }
            return template.relevance;
        },
        async loadCommonResponses() {
            if (this.loadResponcesInProgress) {
                return;
            }
            this.loadResponcesInProgress = true;

            const doc = new GoogleSpreadsheet('1ODyMX9HV_ojVvUMcTAN0bakxKX8sKheLt1zIxQ3eZYE', {
                apiKey: 'AIzaSyA_g3P6VVgjLfqbf7SADbrUewXkebLElA0'
            });

            await doc.loadInfo();

            var result = {};
            var langs = ['en', 'ru'];
            for (var i = 0; i < langs.length; i++) {
                const sheet = doc.sheetsByIndex[i];
                const rows = await sheet.getRows({offset: 0});
                result[langs[i]] = rows.map(function (row) {
                    return {
                        message: row._rawData[0],
                        tags: row._rawData[1],
                        frequency: row._rawData[2],
                        keywords: row._rawData[3],
                        latestUsage: row._rawData[4],
                        shortMessage: row._rawData[6]
                    };
                });
            }

            this.$store.commit('COMMON_RESPONSES_FETCHED', result);
            this.loadResponcesInProgress = false;
        }
    }
}
</script>
<style scoped>

.container-message-display {
    width: 98%;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 40%;
    border-radius: 8px 8px 0 0;
    box-shadow: 0 -8px 8px 1px rgba(0, 0, 0, 0.15);
    margin: 6px 10px 0px 10px;
}

.templates-display {
    width: 98%;
    flex: 1;
    overflow-y: hidden;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
    box-shadow: 0 3px 8px 1px rgba(0, 0, 0, 0.15);
    height: 64px;
    margin: 0px 10px 0px 10px;
}

</style>