<template>
    <label class="switch" :class="{'selected': selected}">
        <input type="checkbox" @click.stop.prevent="onChange">
        <span></span>
    </label>
</template>

<script>
    export default {
        name: "Switch",
        props: ['selected', 'onChange']
    }
</script>

<style scoped>
    .switch {
        cursor: pointer;
    }

    .switch input {
        display: none;
    }

    .switch span {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 20px;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 100px;
        transition: left .5s, background-color .5s, border-color .5s;
        box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1) inset;
        vertical-align: middle;
    }

    .switch span::after {
        content: '';
        position: absolute;
        background-color: #fff;
        top: 0;
        left: 0;
        height: 18px;
        width: 18px;
        border: 1px solid #ddd;
        border-radius: 400px;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    }

    .selected span {
        background-color: #5d9cec;
        border-color: #5d9cec;
        transition: left .5s, background-color .5s, border-color .5s;
    }

    .selected span::after {
        left: 50%;
        transition: left .2s, background-color .2s, border-color .2s;
    }

    .selected input {
        background-color: #5d9cec;
        border-color: #5d9cec;
        transition: left .5s, background-color .5s, border-color .5s;
    }
</style>