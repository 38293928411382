<template>
    <div v-on:mouseenter="loadTranslation(entry)" @mouseleave="hover = false" class="message-cell">
        <div :title="entry.tags.join(', ')" v-if="entry.tags" class="tag-container">
            <Label :label="tag" :closeCallback="removeTag" :extraData="entry" v-for="tag in entry.tags.slice(0, 3)"></Label>
        </div>
        <div title="Status" v-if="entry.status && entry.status !== 'new'" class="entry-status">
            {{entry.status}}
        </div>
        {{ getMessage(entry) }}
        <span v-if="hasTranslation(entry)">
            <img class="flag-icon" :src="getFlagUrl('en')"/>
            {{ getTranslation(entry) }}
        </span>
    </div>
</template>

<script>

import Label from '@/controls/label.vue';
import config from "@/config.js";

export default {
    name: "dialoguemessagecell",
    components: {
        Label
    },
    props: {
        entry: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            hover: false,
        }
    },
    methods: {
        getMessage(entry) {
            if (entry['messageMark'] && entry['messageMark'].indexOf('gift') !== -1) {
                return 'Game administrators sent special present for You!';
            }
            return entry.message;
        },

        hasTranslation(entry) {
            if (entry['messageMark'] && entry['messageMark'].indexOf('gift') !== -1) {
                return false;
            }
            if (entry['language'] && ['ru', 'en'].indexOf(entry['language']) !== -1) {
                return false;
            }

            return entry.enTranslation || this.$store.state.translations[entry.id] !== undefined;
        },
        getTranslation(entry) {
            return entry.enTranslation || this.$store.state.translations[entry.id];
        },
        loadTranslation(entry) {
            this.hover = true;
            if (['ru', 'en'].indexOf(entry['language']) === -1 && !entry.enTranslation && !this.$store.state.translations[entry.id]) {
                setTimeout(function () {
                    if (this.hover) {
                        this.$store.dispatch('translate', {
                            game: entry.game,
                            id: entry.id,
                            message: entry.message,
                            lang: 'en'
                        });
                    }
                }.bind(this), 1000);
            }
        },
        getFlagUrl(lang) {
            var images = require.context('../../../assets/flags', false, /\.svg$/);
            if (lang && config.languages.concat(['da']).indexOf(lang) !== -1) {
                return images('./' + lang + '.svg');
            }
        },
        removeTag(label, entry) {
            this.$store.dispatch('removeTag', {
                userid: entry.userId,
                dialogueid: entry.dialogueId,
                tag: label,
                game: entry.game,
                fromUserCard: false
            });
        }
    }
}
</script>

<style scoped>
.message-cell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.message-cell span {
    max-height: 0;
    display: block;
    color: #5f5f5f;
    text-decoration: none;
    font-size: 14px;
    max-width: 100%;
    font-weight: 300;
    white-space: pre-wrap;
}

.message-cell:hover span {
    max-height: 500px;
    transition: max-height 0.5s ease-in;
    position: relative;
    margin: 2px;
}

.flag-icon {
    width: 13px;
    height: 13px;
    margin-bottom: -1px;
}

.tag-container {
    display: inline-flex;
}

.entry-status {
    display: inline-block;
    color: #1A73E8;
    font-size: 12px;
    font-weight: lighter;
}

</style>