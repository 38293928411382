<template>
    <div :title="template.message" class="template-message-body" :class="{'template-top3': top3, 'template-top': top}" v-on:click="selectMessage(template.message)">
        <div class="template-message-text">{{ template.shortMessage }}</div>
    </div>
</template>

<script>

export default {
    name: "messagetemplate",
    computed: {

    },
    props: {
        template: {
            type: Object,
            required: true
        },
        top: {
            type: Boolean,
            required: false
        },
        top3: {
            type: Boolean,
            required: false
        }
    },
    emits: ['selectTemplate'],
    methods: {
        selectMessage(message) {
            this.$emit('selectTemplate', message);
        }
    }
}
</script>

<style scoped>

.template-message-body {
    display: inline-block;
    padding-left: 10px;
    width: 160px;
}

.template-top3 {
    width: 260px;
}

.template-top .template-message-text {
    font-weight: 900;
    border: 1px #5f5f5f solid;
}

.template-message-text {
    height: 40px;
    background: #fff;
    padding: 2px 10px 6px 0;
    line-height: 15px;
    border-radius: 5px;
    margin: 9px 0 5px 0;
    text-align: center;
    font-size: 14px;
    border-bottom-left-radius: 0px;
    word-break: break-word;
    color: #5f5f5f;
    background-color: rgba(195, 222, 241, 0.5);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.template-message-text:hover {
    color: black;
}

</style>
