<template>
    <div class="container-message-manager">
        <div id="textBox" class="message-text-box">
            <div ref="userInput" class="message-input" placeholder="Reply"
                 tabIndex="0" contenteditable="true" @input="onInput"
                 @paste="onPaste" @keyup.enter.exact="sendMessage" @keydown.esc="cancelEdit"></div>
        </div>
        <div class="container-send-message">
            <button class="send-button" @click="sendMessage">
                <Icon name="sent"/>Send
            </button>
            <button v-if="canGlue" class="action-button" @click="glueDialogue">Glue dialogue</button>
            <div class="switcher switcher-right">
                <Switch :onChange="notTranslateChanged" :selected="getNotTranslateFlag"/>
                <div class="switch-text">not translate</div>
            </div>
        </div>
    </div>
    <modal v-if="showMakePresent" @close="showMakePresent = false" :sendCallback="sendPresent">
        <template v-slot:header>
            <div class="header">
                <h2>Present</h2>
                <DropDown class="expedition-selector" :showSelected="true" :options="utils.listExpeditions(gameName)" v-on:selected="selectExpedition" :placeholder="expedition">></DropDown>
            </div>
        </template>
        <template v-slot:body>
            <div v-if="!utils.isMerge(gameName)" class="presents">
                <img class="present-icon" :src="utils.getRewardUrl(gameName, 'hard')"/>
                <NumberInput title="Hard" v-model="present.hard"/>
                <img v-if="!utils.noSoft(gameName)"  class="present-icon" :src="utils.getRewardUrl(gameName, 'soft')"/>
                <NumberInput v-if="!utils.noSoft(gameName)"  title="Soft" v-model="present.soft"/>
            </div>
            <div v-if="utils.isMerge(gameName)" class="presents">
                <img class="present-icon" :class="{ disabled: !isRewardAllowed('exp')}" :src="utils.getRewardUrl(gameName, 'exp')"/>
                <NumberInput title="Experience" v-model="present.exp"/>
                <img class="present-icon" :class="{ disabled: !isRewardAllowed('hard')}" :src="utils.getRewardUrl(gameName, 'hard')"/>
                <NumberInput title="Hard" v-model="present.hard"/>
            </div>
            <div v-if="utils.isMerge(gameName)" class="presents">
                <img class="present-icon" :class="{ disabled: !isRewardAllowed('lives')}" :src="utils.getRewardUrl(gameName, this.expedition === 'main' ? 'lives' : 'lives2')"/>
                <NumberInput title="Energy" v-model="present.lives"/>
            </div>
            <div v-if="utils.isMerge(gameName)" class="presents">
                <img class="present-icon" :class="{ disabled: !isRewardAllowed('soft')}" :src="utils.getRewardUrl(gameName, 'soft')"/>
                <NumberInput title="Soft" v-model="present.soft"/>
                <img class="present-icon" :class="{ disabled: !isRewardAllowed('wands')}" :src="utils.getRewardUrl(gameName, 'wands')"/>
                <NumberInput title="Royal army" v-model="present.wands"/>
                <img class="present-icon" :class="{ disabled: !isRewardAllowed('worker')}" :src="utils.getRewardUrl(gameName, 'worker')"/>
                <NumberInput title="Worker (in hours)" v-model="present.worker"/>
            </div>
            <div v-if="utils.isMerge(gameName)" class="presents">
                <img class="present-icon" :src="utils.getRewardUrl(gameName, 'growthFund')"/>
                <CheckBox title="GrowthFund" :onChange="toggleGrowthFund" :selected="present.growthFund"/>
            </div>
            <div class="presents">
                <img class="present-icon" :src="utils.getRewardUrl(gameName, 'noAds')"/>
                <CheckBox title="NoAds" :onChange="toggleNoAds" :selected="present.noAds"/>
            </div>
            <div v-if="!utils.noLives(gameName) && !utils.isMerge(gameName)" class="presents">
                <img class="present-icon" :src="utils.getRewardUrl(gameName, 'unlimitedLives')"/>
                <NumberInput title="Unlimited lives (in hours)" v-model="present.unlimitedLives"/>
                <img class="present-icon" :src="utils.getRewardUrl(gameName, 'lives')"/>
                <NumberInput title="Lives" v-model="present.lives"/>
            </div>
            <div v-if="utils.isMatch3(gameName)" class="presents">
                <img class="present-icon" :src="utils.getRewardUrl(gameName, 'booster0')"/>
                <NumberInput title="Spoon" v-model="present.booster0"/>
                <img class="present-icon" :src="utils.getRewardUrl(gameName, 'booster5')"/>
                <NumberInput title="Combo Booster" v-model="present.booster5"/>
            </div>
            <div v-if="utils.isMatch3(gameName)" class="presents">
                <img class="present-icon" :src="utils.getRewardUrl(gameName, 'booster1')"/>
                <NumberInput title="Roller pin" v-model="present.booster1"/>
                <img class="present-icon" :src="utils.getRewardUrl(gameName, 'booster6')"/>
                <NumberInput title="Moves Booster" v-model="present.booster6"/>
            </div>
            <div v-if="utils.isMatch3(gameName)" class="presents">
                <img class="present-icon" :src="utils.getRewardUrl(gameName, 'booster2')"/>
                <NumberInput title="Rainbow rocket" v-model="present.booster2"/>
                <img class="present-icon" :src="utils.getRewardUrl(gameName, 'booster7')"/>
                <NumberInput title="MultiColor Booster" v-model="present.booster7"/>
            </div>
            <div v-if="gameName === 'differences'" class="presents">
                <img class="present-icon" :src="utils.getRewardUrl(gameName, 'booster8')"/>
                <NumberInput title="Brush Booster" v-model="present.booster8"/>
                <img class="present-icon" :src="utils.getRewardUrl(gameName, 'booster9')"/>
                <NumberInput title="Discover Booster" v-model="present.booster9"/>
            </div>
            <div v-if="utils.isSolitaire(gameName)" class="presents">
                <img class="present-icon" :src="utils.getRewardUrl(gameName, 'booster10')"/>
                <NumberInput title="Magnet Booster" v-model="present.booster10"/>
                <img class="present-icon" :src="utils.getRewardUrl(gameName, 'booster11')"/>
                <NumberInput title="Dice Booster" v-model="present.booster11"/>
            </div>
            <div v-if="utils.isSolitaire(gameName)" class="presents">
                <img class="present-icon" :src="utils.getRewardUrl(gameName, 'booster12')"/>
                <NumberInput title="Two Jokers Booster" v-model="present.booster12"/>
            </div>
            <div v-if="gameName === 'scramble'" class="presents">
                <img class="present-icon" :src="utils.getRewardUrl(gameName, 'booster13')"/>
                <NumberInput title="Highlight Booster" v-model="present.booster13"/>
                <img class="present-icon" :src="utils.getRewardUrl(gameName, 'booster14')"/>
                <NumberInput title="Eye Booster" v-model="present.booster14"/>
            </div>
            <div v-if="gameName === 'scramble'" class="presents">
                <img class="present-icon" :src="utils.getRewardUrl(gameName, 'booster15')"/>
                <NumberInput title="Magnifier Booster" v-model="present.booster15"/>
                <img class="present-icon" :src="utils.getRewardUrl(gameName, 'booster16')"/>
                <NumberInput title="Shuffle Booster" v-model="present.booster16"/>
            </div>
            <div v-if="utils.isMatch3(gameName)" class="presents">
                <img class="present-icon" :src="utils.getRewardUrl(gameName, 'stars')"/>
                <NumberInput v-if="utils.isMatch3(gameName)" title="Stars" v-model="present.stars"/>
                <img class="present-icon" :src="utils.getRewardUrl(gameName, 'exp')"/>
                <NumberInput title="Experience" v-model="present.exp"/>
            </div>
            <div v-if="utils.isTile3(gameName)" class="presents">
                <img class="present-icon" :src="utils.getRewardUrl(gameName, 'booster17')"/>
                <NumberInput title="Shuffle Booster" v-model="present.booster17"/>
                <img class="present-icon" :src="utils.getRewardUrl(gameName, 'booster18')"/>
                <NumberInput title="Undo Booster" v-model="present.booster18"/>
            </div>
            <div v-if="utils.isTile3(gameName)" class="presents">
                <img class="present-icon" :src="utils.getRewardUrl(gameName, 'booster23')"/>
                <NumberInput title="Vacuum Booster" v-model="present.booster23"/>
            </div>
            <div v-if="utils.isBlocks(gameName)" class="presents">
                <img class="present-icon" :src="utils.getRewardUrl(gameName, 'booster20')"/>
                <NumberInput title="Replace All Booster" v-model="present.booster20"/>
                <img class="present-icon" :src="utils.getRewardUrl(gameName, 'booster21')"/>
                <NumberInput title="Bomb Booster" v-model="present.booster21"/>
            </div>
            <div v-if="utils.isBlocks(gameName)" class="presents">
                <img class="present-icon" :src="utils.getRewardUrl(gameName, 'booster22')"/>
                <NumberInput title="Undo Booster" v-model="present.booster22"/>
            </div>
            <div class="packs">
                <button v-on:click="clearRewards" style="background: #a7dbd8" class="pack-button">clear</button>
                <button v-on:click="applyPack(packsRewards[packCode])" :title="packsRewards[packCode].name" class="pack-button" v-for="packCode in Object.keys(packsRewards)">
                    {{packCode}}
                </button>
            </div>
        </template>
    </modal>
    <modal v-if="showAddUnit" @close="showAddUnit = false" :sendCallback="sendPresent">
        <template v-slot:header>
            <h2>Add {{ expedition }} unit</h2>
        </template>
        <template v-slot:body>
            <div class="unit-block">
                <DropDown :showSelected="true" :options="availableFamilies" v-on:selected="unitCodeSelected" placeholder='Unit code'>></DropDown>
                <DropDown :showSelected="true" :options="availableStages" v-on:selected="unitStageSelected" placeholder='Unit stage'>></DropDown>
                <NumberInput title="amount" v-model="present.unit.amount"/>
            </div>
        </template>
    </modal>
    <modal v-if="showFixReset" @close="showFixReset = false" :sendCallback="sendPresent">
        <template v-slot:header>
            <h2>Fix reset</h2>
        </template>
        <template v-slot:body>
            <pre>{{fixResetBesideUnits}}</pre>
            <table class="table-block">
                <thead>
                <tr>
                    <th>Code</th>
                    <th>Stage</th>
                    <th>Amount</th>
                </tr>
                </thead>
                <tr v-for="fixResetUnit in fixResetUnits.slice(0, fixResetUnits.length/2)">
                    <td>{{fixResetUnit.unit.code}}</td>
                    <td>{{fixResetUnit.unit.stage}}</td>
                    <td>{{fixResetUnit.unit.amount}}</td>
                </tr>
            </table>
            <table class="table-block">
                <thead>
                <tr>
                    <th>Code</th>
                    <th>Stage</th>
                    <th>Amount</th>
                </tr>
                </thead>
                <tr v-for="fixResetUnit in fixResetUnits.slice(fixResetUnits.length/2)">
                    <td>{{fixResetUnit.unit.code}}</td>
                    <td>{{fixResetUnit.unit.stage}}</td>
                    <td>{{fixResetUnit.unit.amount}}</td>
                </tr>
            </table>
        </template>
    </modal>
    <modal v-if="showHeroesLevel" @close="showHeroesLevel = false" :sendCallback="sendPresent">
        <template v-slot:header>
            <h2>Present</h2>
        </template>
        <template v-slot:body>
            <div class="presents">
                <img class="present-icon" :src="utils.getRewardUrl(gameName, 'hla')"/>
                <NumberInput title="blue hero" :min=-1 v-model="present.hla"/>
                <img class="present-icon" :src="utils.getRewardUrl(gameName, 'hlb')"/>
                <NumberInput title="green hero" :min=-1 v-model="present.hlb"/>
            </div>
            <div class="presents">
                <img class="present-icon" :src="utils.getRewardUrl(gameName, 'hlc')"/>
                <NumberInput title="purple hero" :min=-1 v-model="present.hlc"/>
                <img class="present-icon" :src="utils.getRewardUrl(gameName, 'hld')"/>
                <NumberInput title="red hero" :min=-1 v-model="present.hld"/>
            </div>
            <div class="presents">
                <img class="present-icon" :src="utils.getRewardUrl(gameName, 'hle')"/>
                <NumberInput title="yellow hero" :min=-1 v-model="present.hle"/>
                <img class="present-icon" :src="utils.getRewardUrl(gameName, 'hlw')"/>
                <NumberInput title="white hero" :min=-1 v-model="present.hlw"/>
            </div>
        </template>
    </modal>
    <modal v-if="showSetProgress" @close="showSetProgress = false" :sendCallback="setProgress">
        <template v-slot:header>
            <h2>Progress</h2>
        </template>
        <template v-slot:body>
            <NumberInput @update:modelValue="humanReadableLevelChanged" title="Human Readable Level"
                         v-model="progress.humanReadableLevel"/>
        </template>
    </modal>
    <modal v-if="showRestoreProgress" @close="showRestoreProgress = false" :sendCallback="restoreProgress">
      <template v-slot:header>
        <h2>Restore progress</h2>
      </template>
      <template v-slot:body>
        <StringInput @update:modelValue="restoreProgressUserIdChanged" title="User id" v-model="restoreProgressId"/>
        <div class="userSummary"></div>
        <Properties :properties=userSummary :isTable="false"/>
      </template>
    </modal>
    <modal v-if="showAddHarvested" @close="showAddHarvested = false" :sendCallback="addHarvested">
        <template v-slot:header>
            <h2>Harvested</h2>
        </template>
        <template v-slot:body>
            <div class="presents">
                <img title="apple" class="present-icon" :src="utils.getUnitUrl(gameName, 'apple')"/>
                <NumberInput title="apple" :min=0 v-model="harvested.apple"/>
                <img title="candies" class="present-icon" :src="utils.getUnitUrl(gameName, 'candies')"/>
                <NumberInput title="candies" :min=0 v-model="harvested.candies"/>
            </div>
            <div class="presents">
                <img title="corn" class="present-icon" :src="utils.getUnitUrl(gameName, 'corn')"/>
                <NumberInput title="corn" :min=0 v-model="harvested.corn"/>
                <img title="mushrooms" class="present-icon" :src="utils.getUnitUrl(gameName, 'mushrooms')"/>
                <NumberInput title="mushrooms" :min=0 v-model="harvested.mushrooms"/>
            </div>
            <div class="presents">
                <img title="strawberry" class="present-icon" :src="utils.getUnitUrl(gameName, 'strawberry')"/>
                <NumberInput title="strawberry" :min=0 v-model="harvested.strawberry"/>
                <img title="wheat" class="present-icon" :src="utils.getUnitUrl(gameName, 'wheat')"/>
                <NumberInput title="wheat" :min=0 v-model="harvested.wheat"/>
            </div>
        </template>
    </modal>
    <modal v-if="showMethaProgress" @close="showMethaProgress = false" :sendCallback="setMethaProgress">
        <template v-slot:header>
            <h2>Metha progress</h2>
        </template>
        <template v-slot:body>
            <div v-if="utils.hasFarmMetha(gameName)">
                <NumberInput title="Bulding" v-model="metha.building"/>
                <NumberInput title="Quest" v-model="metha.quest"/>
            </div>
            <div v-if="utils.hasHomeMetha(gameName)">
                <NumberInput title="Furniture" v-model="metha.furniture"/>
                <NumberInput title="Stage" v-model="metha.stage"/>
            </div>
            <div v-if="utils.hasSimpleMetha(gameName)">
                <NumberInput title="Current" v-model="metha.current"/>
            </div>
        </template>
    </modal>
</template>
<script>

import _ from 'lodash';
import Switch from '@/controls/switch.vue';
import config from "@/config.js";
import packsConfig from "@/packsconfig.js";
import utils from "@/utils.js";
import Modal from '@/controls/modal.vue';
import NumberInput from '@/controls/numberinput.vue';
import StringInput from '@/controls/stringinput.vue';
import DropDown from '@/controls/dropdown.vue';
import CheckBox from '@/controls/checkbox.vue';
import Icon from '@/controls/icon.vue';
import Properties from '@/controls/properties.vue';

export default {
    name: 'MessageManager',
    components: {
        Switch,
        Modal,
        NumberInput,
        StringInput,
        DropDown,
        CheckBox,
        Icon,
        Properties
    },
    emits: ['textChanged'],
    computed: {
        userCard() {
            return this.$store.state.userCard ? this.$store.state.userCard : {};
        },
        fixResetUnits() {
            var presentsArray = !Array.isArray(this.present) ? [this.present] : this.present;
            return presentsArray.filter(present => present.unit);
        },
        fixResetBesideUnits() {
            var presentsArray = !Array.isArray(this.present) ? [this.present] : this.present;
            var present = presentsArray.filter(present => !present.unit)[0];
            return "Hard: " + present.hard + ", Energy: " + present.lives + ", Soft: " + present.soft;
        },
        consoleStreamEnabled() {
            var enabled = false;

            if (this.userCard && this.userCard.messages) {
                this.userCard.messages.forEach(function (message) {
                    if (message.messageMark === 'stream_on') {
                        enabled = true;
                    }

                    if (message.messageMark === 'stream_off') {
                        enabled = false;
                    }
                });
            }
            return enabled;
        },
        gameName() {
            return this.$route.params.folder.split(':')[1].split('_')[0].toLowerCase();
        },
        gameFamilies() {
            return config.families[this.gameName];
        },
        getNotTranslateFlag() {
            return this.notTranslate;
        },
        utils() {
            return utils;
        },
        canGlue() {
            return this.$store.state.userCard && this.$store.state.userCard.messages &&
                [...new Set(this.$store.state.userCard.messages.map(item => item.dialogueId))].length > 1;
        },
        version() {
            if (!this.userCard || !this.userCard.userappversion) {
                return -1;
            }

            var version = utils.getGameInfoPropValues(this.userCard, {
                collection: 'userappversion',
                path: 'appversion'
            });

            if (version && version.length > 0 && version[0].indexOf('.') !== -1) {
                return parseInt(version[0].split('.')[1]);
            }
            return -1;
        },
        packsRewards() {
            const packs = packsConfig.packs.find((packs) => packs.name === this.$route.params.folder.split(':')[1].split('_')[0]);
            return packs && packs.rewards || {};
        },
        availableFamilies() {
            return this.gameFamilies.filter(function (family) {
                return family.expeditions.indexOf(this.expedition) !== -1;
            }.bind(this)).map(function (family) {
                return family.code;
            });
        },
        availableStages() {
            if (!this.present.unit || !this.present.unit.code) {
                return [];
            }

            var family = this.gameFamilies.find(family => family.code === this.present.unit.code);
            var result = [];
            for (var stage = 0; stage < family.units.length; stage++) {
                var unit = family.units[stage];
                if (!unit.deleted && !unit.unsuitableForGift) {
                    result.push('' + stage);
                }
            }

            return result;
        },
        userSummary() {
          return this.$store.state.userSummary;
        }
    },
    data() {
        return {
            showMakePresent: false,
            showSetProgress: false,
            showRestoreProgress: false,
            showHeroesLevel: false,
            showMethaProgress: false,
            showAddHarvested: false,
            showAddUnit: false,
            showFixReset: false,

            expedition: "main",

            progress: {},
            restoreProgressId: undefined,
            present: {
                unit: {
                    amount: 1
                }
            },
            metha: {},
            harvested: {},

            translationInProgress: false,
            notTranslate: false
        }
    },
    methods: {
        isRewardAllowed(type) {
            var list = {
                hard: true,
                soft: true,
                gold: true,
                coins: true,
                unit: true,
                lives: ["easter", "rapunzel", "rapunzel2", "rapunzel3", "dragonia2", "dragonia3", "china", "adventure", "halloween", "blackfriday", "xmas"],
                wands: ["collections", "dragonia", "dragonia2", "dragonia3", "undersea", "undersea2", "xmas", "halloween", "blackfriday"],
                exp: ["collections"],
                worker: ["dragonia", "dragonia2", "dragonia3", "undersea", "undersea2", "xmas", "halloween", "adventure", "blackfriday"]
            };

            return this.expedition === "main" || list[type] && (list[type] === true || list[type].includes(this.expedition));
        },
        toggleGrowthFund() {
            this.present.growthFund = this.present.growthFund ? 0 : 1;
        },
        toggleNoAds() {
            this.present.noAds = this.present.noAds ? 0 : 1;
        },
        humanReadableLevelChanged(value) {
            this.progress.episode = Math.floor((value - 1) / 15);
            this.progress.level = (value - 1) % 15;
        },
        showPresentModal() {
            if (this.version !== -1 && this.version < 219 && !window.confirm("User game version 1." + this.version + " may not support gifts. Continue anyway?")) {
                return;
            }

            this.clearRewards();
            this.showMakePresent = true;
        },
        showHarvestedModal() {
            if (this.version !== -1 && this.version < 249 && !window.confirm("User game version 1." + this.version + " may not support changing harvested. Continue anyway?")) {
                return;
            }

            this.clearRewards();
            this.showAddHarvested = true;
        },
        restoreProgressUserIdChanged() {
            if (this.userIdChangedThrottle) {
              return;
            }

            this.userIdChangedThrottle = true;
            setTimeout(() => {
              this.userIdChangedThrottle = false;
              if (this.restoreProgressId && this.restoreProgressId.length > 0) {
                this.$store.dispatch('fetchUserSummary', this.restoreProgressId);
              }
            }, 500);

        },
        showProgressModal() {
            if (this.version !== -1 && this.version < 219 && !window.confirm("User game version 1." + this.version + " may not support gifts. Continue anyway?")) {
                return;
            }

            this.progress = {};
            this.progress.episode = utils.getGameInfoPropValues(this.userCard, {
                collection: 'users',
                path: 'episode'
            })[0];
            this.progress.level = utils.getGameInfoPropValues(this.userCard, {collection: 'users', path: 'level'})[0];
            this.progress.humanReadableLevel = this.progress.episode * 15 + this.progress.level + 1;
            this.showSetProgress = true;
        },
        showRestoreProgressModal() {
          if (this.version !== -1 && this.version < 286 && !window.confirm("User game version 1." + this.version + " may not support restore progress feature. Continue anyway?")) {
            return;
          }
          this.showRestoreProgress = true;
        },
        showMethaProgressModal() {
            let minVersion = 223;
            if (utils.hasHomeMetha(this.gameName)) {
                minVersion = 227;
            }
            if (this.version !== -1 && this.version < minVersion && !window.confirm("User game version 1." + this.version + " may not support metha progress change. Continue anyway?")) {
                return;
            }

            this.metha = {};
            if (utils.hasHomeMetha(this.gameName)) {
                var metha = JSON.parse(utils.getGameInfoPropValues(this.userCard, {
                    collection: 'metha',
                    path: 'data'
                })[0]);

                this.metha.furniture = Object.keys(metha.items).length - 1;
                this.metha.stage = metha.items[metha.furnitureId].stage;
            } else if (utils.hasFarmMetha(this.gameName)) {
                this.metha = JSON.parse(utils.getGameInfoPropValues(this.userCard, {
                    collection: 'metha',
                    path: 'data'
                })[0]);
            } else if (utils.hasSimpleMetha(this.gameName)) {
                this.metha.current = JSON.parse(utils.getGameInfoPropValues(this.userCard, {
                    collection: 'metha',
                    path: 'data'
                })[0]).current;
            }

            this.showMethaProgress = true;
        },
        showHeroesModal() {
            if (this.version !== -1 && this.version < 219 && !window.confirm("User game version 1." + this.version + " may not support gifts. Continue anyway?")) {
                return;
            }

            this.clearRewards();
            this.showHeroesLevel = true;
        },
        glueDialogue() {
            var messageIds = [...new Set(this.$store.state.userCard.messages.map(item => item.id))].sort(function (a, b) {  return a - b;  });
            var dialogueIds = [...new Set(this.$store.state.userCard.messages.map(item => item.dialogueId))].sort(function (a, b) {  return a - b;  });

            if (parseInt(this.$store.state.dialogueId) !== parseInt(dialogueIds[dialogueIds.length-1])) {
                window.alert('Operation allowed only from active dialogue ' + parseInt(dialogueIds[dialogueIds.length-1]));
                return;
            }

            this.$store.dispatch('glueDialogue', {
                dialogueid: this.$store.state.dialogueId,
                targetdialogueid: dialogueIds[dialogueIds.length-2],
                topmessageid: messageIds[messageIds.length-1],
                userid: this.$route.params.userId,
                gamename: this.$route.params.folder.split(':')[1]
            });
        },
        showAddUnitModal(expedition) {
            if (expedition === "main" && this.version !== -1 && this.version < 229 && !window.confirm("User game version 1." + this.version + " may not support unit as gift. Continue anyway?")) {
                return;
            }

            this.clearRewards();
            this.selectExpedition(expedition);
            this.showAddUnit = true;
        },
        showFixResetModal() {
            if (this.version !== -1 && this.version < 229 && !window.confirm("User game version 1." + this.version + " may not support unit as gift. Continue anyway?")) {
                return;
            }

            this.clearRewards();

            this.present = this.gameFamilies.map(family =>
                utils.getMergeFamilyPresent(this.$store.state.userCard, family)).filter(presentUnit => presentUnit);

            var lives, soft, hard;
            switch (utils.classifyPayer(this.userCard)) {
                case 2: hard = lives = soft = 200; break;
                case 3: hard = lives = soft = 300; break;
                case 4: hard = lives = soft = 1000; break;
                default: hard = lives = soft = 100; break;
            }
            this.present.push({hard, lives, soft});

            this.showFixReset = true;
        },
        setProgress() {
            var text = JSON.stringify({
                progress: {
                    episode: this.progress.episode,
                    level: this.progress.level
                }
            });
            this.dispatchMessage(text, 'gift');
        },
        restoreProgress() {
          if (!this.$store.state.userSummary || this.$store.state.userSummary.length === 0) {
            window.alert("No user found");
            return;
          }

          var text = JSON.stringify({
            restoreProgressId: this.restoreProgressId
          });
          this.dispatchMessage(text, 'gift');
        },
        addHarvested() {
            var messageBody = {};
            Object.keys(this.harvested).forEach(function (code) {
                if (this.harvested[code] > 0) {
                    messageBody[code] = this.harvested[code];
                }
            }.bind(this));

            this.dispatchMessage(JSON.stringify({
                harvested: messageBody
            }), 'gift');
        },
        setMethaProgress() {
            var metha = {};

            if (utils.hasHomeMetha(this.gameName)) {
                metha.home = {
                    furniture: this.metha.furniture,
                    stage: this.metha.stage
                };
            } if (utils.hasFarmMetha(this.gameName)) {
                metha.farm = {
                    building: this.metha.building,
                    quest: this.metha.quest
                };
            } else if (utils.hasSimpleMetha(this.gameName)) {
                metha.simple = {
                    current: this.metha.current
                };
            }

            var text = JSON.stringify({metha});
            this.dispatchMessage(text, 'gift');
        },
        applyPack(packRewards) {
            this.clearRewards();

            Object.keys(packRewards).forEach(function (reward) {
               if (this.present[reward] !== undefined) {
                   this.present[reward] = packRewards[reward];
               }
            }.bind(this));
        },
        sendPresent() {
            if (Array.isArray(this.present)) {
                this.present.slice().forEach(function (present) {
                    this.present = present;
                    this.sendPresent();
                }.bind(this));
                return;
            }

            if (this.version < 318) {
                var replaceTargets = [];
                var replaceItems = [];
                if (this.present.soft) {
                    replaceTargets.push("soft");
                    replaceItems.push("coins");
                }
                if (this.present.hard) {
                    replaceTargets.push("hard");
                    replaceItems.push("gold");
                }
                if (replaceTargets.length > 0 && window.confirm("User game version 1." + this.version + " may not support " + replaceTargets.join(" and ") + ". Replace with " + replaceItems.join(" and ") + "?")) {
                    this.present.coins = this.present.soft;
                    this.present.soft = 0;
                    this.present.gold = this.present.hard;
                    this.present.hard = 0;
                }
            }

            var presents = [];
            for (var type in this.present) {
                if (this.present[type] > 0 || (type.indexOf('hl') !== -1 && this.present[type] === 0)) {
                    presents.push({
                        type: type,
                        amount: parseInt(this.present[type])
                    });
                }
            }

            if (this.present.unit && this.present.unit.code) {
                presents.push({
                    type: 'unit',
                    code: this.present.unit.code,
                    stage: this.present.unit.stage || 0,
                    amount: this.present.unit.amount || 1
                });
            }

            if (presents.length === 0) {
                alert('cannot send empty present!');
                return;
            }

            var presentObj = {
                presents: presents
            };

            if (this.present.lives && this.present.lives > 0 && this.expedition !== "main"
                && this.version !== -1 && this.version < 349
                && !window.confirm("User game version 1." + this.version + " may not support lives for expeditions . Continue anyway?")) {
                return;
            }

            for (var i = 0; i < presents.length; ++i) {
                if (!this.isRewardAllowed(presents[i].type)) {
                    alert("can't send " + presents[i].type + " to " + this.expedition + ".");
                    return
                }
            }

            if (this.expedition !== "main") {
                presentObj.expedition = this.expedition;

                // backward compatibility with old chat clients
                presentObj.expeditionType = this.utils.getExpeditionType(this.expedition);
            }

            this.dispatchMessage(JSON.stringify(presentObj), 'gift');
        },
        clearRewards() {
            this.present = {
                hard: 0, gold: 0,
                booster0: 0, booster1: 0, booster2: 0, booster5: 0, booster6: 0, booster7: 0, booster8: 0, booster9: 0,
                booster10: 0, booster11: 0, booster12: 0, booster13: 0, booster14: 0, booster15: 0, booster16: 0,
                booster17: 0, booster18: 0, booster20: 0, booster21: 0, booster22: 0, booster23: 0,
                unlimitedLives: 0, lives: 0, stars: 0, exp: 0, soft: 0, coins: 0, wands: 0, worker: 0,
                hla: -1, hlb: -1, hlc: -1, hld: -1, hle: -1, hlw: -1, unit: { amount: 1},
                growthFund: 0, noAds: 0
            };
            this.harvested = {wheat: 0, candies: 0, apple: 0, strawberry: 0, mushrooms: 0, corn: 0};
            this.selectExpedition("main");
        },
        unitCodeSelected(code) {
            this.present.unit.code = code;
            this.present.unit.stage = undefined;
        },
        unitStageSelected(stage) {
            this.present.unit.stage = parseInt(stage);
        },
        selectExpedition(expedition) {
            this.expedition = expedition;
        },
        dispatchMessage(text, messageMark, originalText) {
            var payerlevel = utils.classifyPayer(this.userCard);
            var name = this.userCard.userNames && this.userCard.userNames.length > 0 ?
                this.userCard.userNames[0].name : "<NONAME>";
            var language = this.userCard.settings ? utils.getGameInfoPropValues(this.userCard, {collection: 'settings', path: 'language'})[0] : 'en';
            var source = utils.getGameInfoPropValues(this.userCard, {collection: 'users', path: 'source'})[0];

            this.$store.dispatch('sendAnswer', {
                isresponse: 1,
                payerlevel: payerlevel,
                name: name,
                language: language,
                source: source,
                message: text,
                userId: this.$route.params.userId,
                dialogueId: this.$store.state.dialogueId,
                messageMark: messageMark,
                respondingPerson: this.$store.state.respondingPerson || 'me',
                gamename: this.$route.params.folder.split(':')[1],
                enTranslation: originalText
            });
        },
        dispatchEditedMessage(id, text) {
            this.$store.dispatch('editMessage', {
                id: id,
                userid: this.$route.params.userId,
                dialogueid: this.$store.state.dialogueId,
                message: text
            });
        },
        cancelEdit() {
            if (this.editedMessageId) {
                this.$refs.userInput.textContent = '';
                this.editedMessageId = undefined;
            }
        },
        onInput: _.throttle(function() {
            this.$emit('textChanged', this.$refs.userInput.textContent);
        }, 2000),
        sendMessage: _.throttle(function() {
            var textInput = this.$refs.userInput.textContent;
            if (textInput.length === 0 || this.translationInProgress) {
                return;
            }

            var textToSend = textInput.trim();

            if (textToSend.length >= 500) {
                alert("Message too long. Please make it shorter.");
                return;
            }

            this.$refs.userInput.textContent = '';

            if (this.editedMessageId) {
                this.dispatchEditedMessage(this.editedMessageId, textInput.length <= 500 ? textInput : textInput.substr(0, textInput.lastIndexOf(' ', 500)));
                this.editedMessageId = undefined;
                return;
            }

            var userLanguage = this.userCard.settings && this.userCard.settings.length > 0 ? this.userCard.settings[0].language : undefined;
            if (this.userCard.translateLanguage) {
                userLanguage = this.userCard.translateLanguage;
            }

            if (!this.notTranslate && ['en', 'ru'].indexOf(userLanguage) === -1 && !this.translationInProgress) {
                var originalText = textInput.trim();
                this.$store.dispatch('translate', {
                    game: this.$route.params.folder.split(':')[1],
                    id: "MessageTranslation",
                    message: originalText,
                    lang: userLanguage,
                    callback: function () {
                        var messageText = this.$store.state.translations["MessageTranslation"];
                        this.translationInProgress = false;
                        this.$store.state.translations["MessageTranslation"] = undefined;

                        if (messageText.length >= 500) {
                            this.$refs.userInput.textContent = originalText;
                            alert("Message too long. Please make it shorter.");
                        } else {
                            this.dispatchMessage(messageText, '', originalText);
                        }
                    }.bind(this)
                });
                this.translationInProgress = true;
            } else {
                this.dispatchMessage(textToSend, '');
            }
        }, 500),
        onPaste(e) {
            e.preventDefault();
            var text = e.clipboardData.getData("text/plain");
            document.execCommand("insertHTML", false, text);
        },
        editMessage(messageText, messageId) {
            this.$refs.userInput.textContent = messageText;
            this.editedMessageId = messageId;
        },

        notTranslateChanged() {
            this.notTranslate = !this.notTranslate;
        }
    }
}
</script>
<style scoped>

.pack-button {
    background: #e7df9a;
    border-radius: 6px;
    border: none;
    height: 20px;
    width: 100px;
    color: #5f5f5f;
    margin: 5px;
    overflow: hidden;
}

.pack-button:hover {
    color: black;
}

.packs {
    margin-top: 14px;
    margin-left: 72px;
}

.unit-block {
    margin-top: 14px;
    margin-left: 72px;
}

.table-block {
    display: inline-table;
    margin-left: 40px;
    text-align: center;
}
.table-block > tr,td {
    border-bottom: 1px solid #e5e5e5;
}

.unit-block > .dropdown {
    margin: 5px;
}

.unit-block > .number-input {
    margin: 50px 5px 5px;
    display: inline-block;
    max-width: 100px;
}

.presents {
    display: inline-flex;
    margin-right: 20px;
}

.present-icon {
    width: 40px;
    height: 40px;
    object-fit: contain;
    margin: 5px 10px 0 30px;
}

.disabled {
    filter: grayscale(1);
}

.presents .check {
    margin-top: 15px
}

.header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
}

.header > * {
    margin: 0;
}

.header > h2 {
    margin-right: auto
}

.expedition-selector :deep(.dropdown-input) {
    font-size: 1.3em;
    font-weight: bold;
    color: red;
    border: 2px solid red;
}

.expedition-selector :deep(.dropdown-input:focus-visible) {
    outline: none
}

.expedition-selector :deep(.dropdown-input::placeholder) {
    color: red;
}

.container-message-manager {
    background: #fff;
    display: flex;
    align-items: center;
    padding: 0 20px 0 20px;
}

.container-message-manager .message-text-box {
    flex: 1;
    overflow: hidden;
}

.container-message-manager {
    width: 98%;
    height: 120px;
    box-sizing: border-box;
    color: #565867;
    bottom: 20px;
    display: inline-block;
    box-shadow: 0 8px 8px 1px rgba(0, 0, 0, 0.2);
    border-radius: 0 0 8px 8px;
    padding: 6px;
    margin: 0px 10px 10px 10px;
}

.container-message-manager .message-input {
    width: 100%;
    height: 70px;
    resize: none;
    outline: none;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.33;
    white-space: pre-wrap;
    word-wrap: break-word;
    color: black;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: left;
    cursor: text;
    border-bottom: 1px solid #e5e5e5;
}

.container-message-manager .message-input:empty:before {
    display: block;
}

.container-message-manager .message-input:focus {
    outline: none;
}

.container-send-message {
    margin: 10px;
}

.send-button {
    float: right;
    background-color: #007bff;
    border-color: #007bff;
    border: 1px solid transparent;
    border-radius: 6px;
    font-size: 1rem;
    line-height: 1.3;
    height: 30px;
    font-weight: 400;
    width: 100px;
    color: white;
    margin-top: -4px;
}

.send-button i {
    margin-left: -2px;
}

.action-button {
    background: rgba(0, 168, 168, 0.7);
    border-radius: 6px;
    border: none;
    height: 30px;
    width: 100px;
    color: white;
    margin-top: -4px;
}

.switcher {
    margin-top: -10px;
    display: inline-block;
}

.switcher-right {
    float: right;
    margin-right: 8px;
    margin-top: -6px;
}

.switch-text {
    font-size: small;
    margin-left: -10px;
}

.userSummary {
  margin-top: 50px;
}

</style>
