/**
* Created by Andrey Popov on 10/1/21.
*/

<template>
    <table v-if="isTable && properties && properties.length > 0" class="properties-table">
        <thead>
        <tr>
            <th :style="{'width': prop.type === 'date' ? '140px' : 'auto'}" v-for="prop in properties">{{ prop.header }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="row in rows">
            <td v-for="prop in properties">
                <button v-if="row[prop.header] && row[prop.header].actions" v-for="action in row[prop.header].actions" @click="action.action">{{action.caption}}</button>
                <div class="property-icon-value" v-if="row[prop.header] && row[prop.header].iconValue">{{ row[prop.header].iconValue }}</div>
                <Icon v-if="row[prop.header] && row[prop.header].icon" :name="row[prop.header].icon" :title="row[prop.header].iconTitle"></Icon>
                <div :title="row[prop.header]" v-if="!prop.type">{{ row[prop.header] && (row[prop.header].actions || row[prop.header].icon) ? '' : row[prop.header] }}</div>
                <div v-if="prop.type === 'date'">
                    <div>{{fullDate(row[prop.header])}}</div>
                    <div class="small-text-table">{{fromNowDate(row[prop.header])}}</div>
                </div>
            </td>
        </tr>
        </tbody>
    </table>
    <div v-if="!isTable" class="properties-list">
        <div class="property" v-for="item in listItems">
            <div v-if="item.value != 0" class="property-name">{{item.name}}</div>
            <div v-if="!item.type" class="property-value">{{item.value}}</div>
            <div v-if="item.type === 'id'" v-on:click="copyId(item.value)" class="property-value property-value-id">{{item.value}}</div>
            <div v-if="item.type === 'date'" class="property-value">
                <div>{{fullDate(item.value)}}</div>
                <div class="small-text">{{fromNowDate(item.value)}}</div>
            </div>
            <div v-if="item.type === 'size'" class="property-value">{{item.value.width}}x{{item.value.height}}</div>
            <div v-if="item.type === 'agent' && item.value" class="property-value">{{item.value.name}} ver.{{item.value.version}}</div>
            <div v-if="item.type === 'country' && item.value" class="property-value">{{getCountry(item.value)}} {{item.value}}</div>
            <div v-if="item.type === 'userLink' && item.value && item.value !== '0'" class="property-value"><a :href="getUserCardLink(item.value)" title="new user ID" target="_blank">{{item.value}}</a></div>
          <div v-if="item.type === 'useridshistory'" class="property-value">{{fromArray(item.value)}}</div>
        </div>
    </div>
</template>

<script>

import moment from "moment";
import Icon from "./icon.vue";
import utils from "@/utils.js";

export default {
    name: "Properties",
    components: {Icon},
    props: {
        properties: {
            type: Array,
            required: true
        },
        isTable: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        rows() {
            var result = [];
            this.properties.forEach(property => {
                property.values.forEach((value, index) => {
                    if (!result[index]) {
                        result.push({});
                    }

                    result[index][property.header] = value;
                })
            });
            return result;
        },
        listItems() {
            return this.properties.filter(prop => prop.values.length > 0 && prop.values[0] !== '')
                .map(prop => {
                    return {
                        name: prop.header,
                        type: prop.type,
                        value: prop.values[0]
                    }
                });
        }
    },
    methods: {
        fullDate(str) {
            if (!str || str.length === 0) {
               return '';
            }
            return moment(str).format('HH:mm DD MMM YYYY');
        },
        fromNowDate(str) {
            if (!str || str.length === 0) {
              return '';
            }
            return moment(str).fromNow();
        },
        copyId(id) {
            navigator.clipboard.writeText(id).then(() => {
              console.log("id copied");
            });
        },
        getCountry(code) {
          return utils.getCountryName(code);
        },
        getUserCardLink(userId) {
            var routerData = {
                name: 'UserCard', params: {
                    folder: this.$route.params.folder,
                    userId: userId
                }
            };

            return this.$router.resolve(routerData).href;
        },
        fromArray(string) {
          return string.replace(/\[|\]|\"/g, '').replace(/,/g, ", ");
        },
    }
}
</script>

<style scoped>
table.properties-table {
    table-layout: fixed;
    margin: 12px;
    width: 95%;
}

table.properties-table th {
    border-bottom: 1px solid #e5e5e5;
    padding: 4px;
    font-size: 0.8rem;
    color: #5f5f5f;
    overflow: hidden;
    word-break: keep-all;
    height: 30px;
}

table.properties-table td {
    border-bottom: 1px solid #e5e5e5;
    white-space: pre-line;
    text-align: center;
    padding: 2px;
    overflow: hidden;
}

.game-info table.properties-table td {
    text-align: left;
}

.game-info table.properties-table tr th:first-child,
.game-info table.properties-table tr td:first-child {
    width: 12%!important;
}

td button {
    margin-left: 5px;
}

.small-text-table {
    font-size: 0.8rem;
    line-height: 0.6rem;
    color: #5f5f5f;
    text-align: right;
    margin-right: 5px;
    padding-bottom: 2px;
}

.properties-list {
    height: 100%;
    margin: 6px 4px;
    min-width: 280px;
}

.property {
    display: flex;
}

.property-value-id:hover {
  cursor: copy;
}

.property-name {
    font-size: 0.8rem;
    flex-basis: 90px;
    -webkit-box-align: center;
    box-align: center;
    align-items: center;
    display: flex;
    margin-right: 14px;
    margin-left: 6px;
    padding-bottom: 2px;
    color: #5f5f5f;
}

.property-value {
    letter-spacing: .00625em;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    hyphens: auto;
    word-break: keep-all;
    color: #202124;
    margin: 0;
    padding: 0;
    width: 100%;
}

.small-text {
    font-size: 0.8rem;
    line-height: 0.5rem;
    padding-bottom: 6px;
    text-align: left;
    color: #5f5f5f;
}

.property-icon-value {
    display: inline-block;
}

</style>