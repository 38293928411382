/**
 * Created by r4zi4l on 20.08.2021
 */

Families.initialize = function () {
    Families.initializeBase();

    delete Families.warcraft;
    delete Families.warcraftchest;
    delete Families.warcraftsource;
    delete Families.warcraftgenerator;

    delete Families.steampunk;
    delete Families.steampunkchest;
    delete Families.steampunksource;
    delete Families.steampunkgenerator;

    delete Families.moonstone;
    delete Families.moonstonechest;
    delete Families.moonstonesource;
    delete Families.moonstonegenerator;

    delete Families.fish;
    delete Families.panda;

    delete Families.coinsplantchest;
    delete Families.rubiesplantchest;

    Families.afterInitialize();
};

if (typeof cc === "undefined") {
    module.exports = Families;
}
